import { XMarkIcon } from '@heroicons/react/24/outline'
import React from 'react'
import { Achievement } from '../../types/index'
import Spinner from 'react-spinkit'

type Props = {
  achievement: Achievement
  dismissable?: boolean
  onDismiss?: () => void
  onOptIn: () => void
  isAssociated?: boolean
}

const OptInAchievement = ({ achievement, onDismiss, onOptIn, dismissable, isAssociated = false }: Props) => {
  const [disabled, setDisabled] = React.useState(false)

  // callback to opt-in to an achievement and set disabled on click
  const handleOptIn = () => {
    setDisabled(true)
    // artifically delay the opt-in to show the loading indicator
    setTimeout(() => {
      onOptIn()
    }, 200)
  }

  return (
    <>
      {isAssociated && <div className="text-slate-900 font-semibold center w-full text-center   text-lg mb-2">Boost your challenge with this associated Leanchievement!</div>}
      <div className="max-w-md mx-auto border border-cyan-300 rounded-xl shadow-lg overflow-hidden md:max-w-lg">
        <div className="flex flex-row pl-4">
          <img className="w-24 h-24 sm:w-28 sm:h-28 mr-2 my-auto border border-slate-300 shadow-sm" src={achievement.badgeImageUrl} alt={`${achievement.name} badge`} />
          <div className="p-2 flex flex-col justify-between w-full">
            {dismissable && (
              <button className="transition ease-in-out duration-300 self-end bg-teal hover:bg-cyan-500 text-white font-bold py-1 px-1 rounded " onClick={onDismiss}>
                <XMarkIcon className="h-4 w-4" />
              </button>
            )}
            <div className="mt-1 font-bold text-base md:text-lg lg:text-xl mb-2">{achievement.name}</div>
            <p className="text-sm mb-2">{achievement.descriptionMd}</p>
            <button
              className={`transition ease-in-out duration-300 bg-cyan-500 hover:bg-cyan-700 text-white font-bold py-1 px-2 rounded self-end ${
                disabled ? 'opacity-50 pointer-events-none' : ''
              }`}
              onClick={handleOptIn}
              disabled={disabled}
            >
              {!disabled ? 'Opt-In' : <Spinner fadeIn="quarter" className="loadingIndicator" name="three-bounce" color="#FFF" />}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export { OptInAchievement }
