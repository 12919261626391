import React from 'react'
import styles from './WorkoutOverview.module.scss'
import {
  Box,
  Button,
  FinisherItem,
  LoadingIndicator,
  useVideoPlayerContext,
  VideoPlayer,
  VideoPlayerContextProvider,
} from 'shared/components'
import FlexFinisher from 'components/FlexFinisher'

const WorkoutOverviewView: React.FC = () => {
  const {
    workoutLoading,
    activeWorkout,
    handlePlayClick,
    isFinisher,
    onClickBackToWorkout,
    showWorkoutComplete,
    onClickWorkoutComplete,
    createCompletedLoading,
    setActiveWorkout,
  } = useVideoPlayerContext()

  if (workoutLoading) {
    return <LoadingIndicator />
  }

  if (activeWorkout) {
    console.log('activeWorkout :>> ', activeWorkout)
  }

  return (
    <Box className={styles.container}>
      <VideoPlayer />
      <div className="vjs-playlist" />
      <Box className={styles.contentContainer}>
        <p className={styles.workoutTitle}>{activeWorkout?.title}</p>
        <p className={styles.workoutSubTitle}>{`${activeWorkout?.equipment ? 'Requires Equipment' : 'No Equipment'} • ${
          activeWorkout?.duration.title
        }`}</p>
        <Box className={styles.content}>
          {showWorkoutComplete && (
            <Button
              label="End Workout"
              onClick={onClickWorkoutComplete}
              loading={createCompletedLoading}
              classNames={`mt-2 h-12 w-full justify-center rounded-2xl transition-all border border-transparent bg-coral px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-coral-dark focus:outline-none focus:ring-2 focus:ring-coral-dark focus:ring-offset-2 sm:text-sm`}
            />
          )}
          <p className={styles.headerTitle}>WORKOUT SUMMARY</p>
          <p className={styles.workoutSummary}>{activeWorkout?.summary}</p>

          {activeWorkout?.finisher && (
            <Box className={styles.finisherSection}>
              <p className={styles.headerTitle}>RECOMMENDED FINISHER</p>
              {/* <FinisherItem
                title={activeWorkout.finisher.title}
                description={activeWorkout.finisher.subtitle}
                duration={activeWorkout.finisher.workout?.duration?.title}
                item={activeWorkout.finisher.workout || undefined}
                onClickPlay={handlePlayClick}
              /> */}

              <FlexFinisher
                key={`finisher-${activeWorkout.finisher.workout.id}}`}
                finisher={activeWorkout.finisher.workout}
                parentWorkout={activeWorkout}
                useContext={true}
                size="large"
              />
            </Box>
          )}
          {isFinisher && <Button containerStyle={styles.backToWorkoutBtn} label="Back to Workout" onClick={onClickBackToWorkout} />}
        </Box>
      </Box>
    </Box>
  )
}

export const WorkoutOverview: React.FC = () => {
  return (
    <VideoPlayerContextProvider>
      <WorkoutOverviewView />
    </VideoPlayerContextProvider>
  )
}
