import React, { useEffect, useMemo, useState } from 'react';
import Flip from './Flip';
import dayjs from 'shared/functions/dayjs';

type Props = {
  date: Date;
  weekNumber: number;
  maxWeeks: number;
};

type TimeLeft = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

const calculateTimeLeft = (date: Date) => {
  const now = dayjs();
  const end = dayjs(date);
  const diff = end.diff(now);
  const timeLeft = dayjs.duration(diff);
  return {
    days: timeLeft.days(),
    hours: timeLeft.hours(),
    minutes: timeLeft.minutes(),
    seconds: timeLeft.seconds(),
  };
};

const Countdown = ({ date, maxWeeks, weekNumber }: Props) => {
  const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft(date));
  let timer: NodeJS.Timeout;
  const updateCount = () => {
    if (!timer) {
      timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft(date));
      }, 5000);
    }
  };

  useEffect(() => {
    updateCount();

    if (timer) {
      return () => clearInterval(timer);
    }
  }, [timeLeft]);

  if (maxWeeks === 0) return null;
  if (weekNumber === 0) return null;
  if (weekNumber > maxWeeks) return null;

  const isLastWeek = weekNumber === maxWeeks;
  const nextWeekNumber = weekNumber + 1;

  //   let timeLeft = calculateTimeLeft;

  return (
    <div className="flex flex-col">
      <div className="">
        <div className="text-xs text-gray-500">{isLastWeek ? 'Till end of challenge' : `Countdown until next week`}</div>
      </div>

      <div className="flex font-semi-bold ">
        <div className="flex flex-row">
          <Flip value={timeLeft.days} />
          <div className="px-1">:</div>
        </div>
        <div className=" flex flex-row">
          <Flip value={timeLeft.hours} />
          <div className="px-1">:</div>
        </div>
        <div className="">
          <Flip value={timeLeft.minutes} />
        </div>
      </div>
      <div className="">
        <div className="flex text-xxs">
          <div className="text-center ">
            <div className=" text-gray-500">Days</div>
          </div>
          <div className="text-center ml-2">
            <div className=" text-gray-500">Hrs</div>
          </div>
          <div className="text-center ml-3">
            <div className=" text-gray-500">Mins</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Countdown;
