import { User } from 'types'
import { get, patch, post } from './helpers'
import { CreateUserOptions, UpdateUserOptions, UserResponse } from './types'

export const transformUserResponseToUser = (user: UserResponse): User => {
  return {
    id: user.id,
    email: user.email,
    firstName: user.first_name,
    lastName: user.last_name,
    profilePicture: user.profile_picture,
    pushToken: user.push_token,
    heightCM: user.height_cm,
    weightKG: user.weight_kg,
    unitPreference: user.unit_preference,
    chatToken: user.chat_token,
    subscription: user.subscription,
    preferences: user.preferences,
    birthday: user.birthday,
    gender: user.gender,
    bmrRange: user.bmr_range,
    streak: user.streak,
    highestStreak: user.highest_streak,
  }
}

export const createUser = async (data: CreateUserOptions): Promise<User> => {
  const response = await post<UserResponse>(`/users`, data)
  const user = transformUserResponseToUser(response)
  return user
}

export const getUser = async (): Promise<User> => {
  const response = await get<UserResponse>(`/users`)
  const user = transformUserResponseToUser(response)
  return user
}

// Get User by email
export const getUserByEmail = async (email: string): Promise<User | null | any> => {
  const queryEmail = encodeURIComponent(email)
  let user = await get(`/admin/users?email=${queryEmail}`)
  // throw error if not found or null
  if (!user) {
    console.log('User not found')
    throw new Error('User not found')
  }
  user = transformUserResponseToUser(user as UserResponse)
  console.log('user :>> ', user)
  return user
}

export const updateUser = async (data: UpdateUserOptions): Promise<User> => {
  const response = await patch<UserResponse>(`/users`, data)
  const user = transformUserResponseToUser(response)
  return user
}
