import { get, post } from './helpers'

const pause = async (userChallengeId: string): Promise<boolean> => {
  let response: string | { status: number; data: unknown }
  try {
    response = await post(`/userChallenges/${userChallengeId}/pause`, undefined)
    console.log({ response })
  } catch (error) {
    console.error('Error pausing user challenge:', error)
    return false
  }

  if (response === 'OK') {
    return true
  } else {
    return false
  }
}

const unpause = async (userChallengeId: string): Promise<boolean> => {
  let response: string | { status: number; data: unknown }
  try {
    response = await post(`/userChallenges/${userChallengeId}/unpause`, undefined)
    console.log({ response })
  } catch (error) {
    console.error('Error unpausing user challenge:', error)
    return false
  }

  if (response === 'OK') {
    return true
  } else {
    return false
  }
}

const isPaused = async (userChallengeId: string): Promise<boolean> => {
  let response: boolean
  try {
    response = await get(`/userChallenges/${userChallengeId}/paused`)
    console.log({ response })
    return response === true ? true : false
  } catch (error) {
    console.error('Error checking if user challenge is paused:', error)
    return false
  }
}

const getAllCompletedUserChallengesCount = async (): Promise<{ [key: string]: number } | null> => {
  try {
    const response = (await get(`userChallenges/allCompletedCounts`)) as { [key: string]: number }
    return response
  } catch (error) {
    console.error('Error getting all completed user challenges count:', error)
    return null
  }
}

export { pause, unpause, isPaused, getAllCompletedUserChallengesCount }
