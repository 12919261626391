import React, { useEffect, useState } from 'react'
import { Achievement, UserAchievement, UserAchievementCriteria } from '../../types/index'
import { StarIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { useUserAchievementCriteriaState, useUserAchievementCriteriaActions } from '../../hooks/useUserAchievementCriteria'
import { useUserAchievements } from '../../hooks/useUserAchievements'
import calculateAchievementProgress from '../../services/calculateAchievementProgress'
import AchievementCriteria from './CriteriaProgress'
import { TrashIcon } from '@heroicons/react/24/outline'
import ConfirmationModal from '../ConfirmationModal'

type Props = {
  achievement: Achievement
  userAchievement?: UserAchievement
  dismissable?: boolean
}

const colorSets: { [key: number]: string[] } = {
  1: ['#9dfff8'],
  2: ['#72e2df', '#9dfff8'],
  3: ['#88f6ec', '#72e2df', '#48b8c6'],
  4: ['#88f6ec', '#72e2df', '#5dcdd3', '#48b8c6'],
  5: ['#9dfff8', '#88f6ec', '#72e2df', '#5dcdd3', '#48b8c6'],
  6: ['bg-gray-200', 'bg-blue-500', 'bg-green-500', 'bg-red-500'],
}

export default function AchievementProgress({ achievement, userAchievement, dismissable }: Props) {
  const [isDismissable, setIsDismissable] = useState(dismissable ?? false)
  const [isInTheFuture, setIsInTheFuture] = useState(false)
  const [differenceInDays, setDifferenceInDays] = useState(0)
  const [hasEnded, setHasEnded] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const UacState = useUserAchievementCriteriaState()
  const UacActions = useUserAchievementCriteriaActions()
  const [achievementProgress, setAchievementProgress] = useState<{
    achievementId: string
    progress: number
    starRating: number
    breakdown: { uacId: string; uacProgress: number }[]
  } | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { removeUserAchievement, refresh } = useUserAchievements()

  useEffect(() => {
    if (!userAchievement || !userAchievement.startDate) return
    const now = new Date()
    const startDate = new Date(userAchievement.startDate)
    if (now < startDate) {
      setIsInTheFuture(true)
    }
  }, [isInTheFuture])

  useEffect(() => {
    if (!userAchievement || !userAchievement.endDate) return
    const now = new Date()
    const endDate = new Date(userAchievement.endDate)
    if (now > endDate) {
      setHasEnded(true)
    }
  }, [hasEnded])

  useEffect(() => {
    if (!userAchievement || !achievement) return
    if (dismissable === false) return
    if (userAchievement.completed === false) {
      setIsDismissable(true)
    }
  }, [userAchievement])

  useEffect(() => {
    if (!userAchievement || !achievement) return
    const userAchievementCriteriaForAchievement = achievement.achievementCriteria
      ?.map((criteria) => {
        if (!criteria || !criteria.id) return null
        const value = UacActions.findByAchievementCriteriaId(criteria.id)
        if (!value) return null
        return value
      })
      .filter((value) => value !== null) as UserAchievementCriteria[]

    if (userAchievementCriteriaForAchievement.length === 0) {
      return
    }

    if (userAchievementCriteriaForAchievement) {
      if (!userAchievement) {
        console.error(`%c userAchievement is null maybe it shouldn't be 🤔 `, 'color: red')
        return
      }
      if (!achievement) {
        console.error(`%c achievement is null maybe it shouldn't be 🤔 `, 'color: red')
        return
      }
      if (!UacState.userAchievementCriteria) {
        console.error(`%c UacState.userAchievementCriteria is null maybe it shouldn't be 🤔 `, 'color: red')
        return
      }

      let uacProgress = null
      try {
        uacProgress = calculateAchievementProgress(achievement, userAchievementCriteriaForAchievement)
      } catch (error) {
        console.error(`%c Error calculating progress for ${achievement.name}`, 'color: red')
        console.error(error)
      }
      setAchievementProgress(uacProgress)
    }
  }, [UacState.userAchievementCriteria, userAchievement, achievement])

  useEffect(() => {
    if (!userAchievement || !userAchievement.startDate) return
    const now = new Date()
    const startDate = new Date(userAchievement.startDate)

    const today = new Date()
    const differenceInTime = startDate.getTime() - today.getTime()
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24))
    setDifferenceInDays(differenceInDays)
  }, [userAchievement])

  if (UacState.isLoading || !UacActions || UacState.userAchievementCriteria.size === 0) {
    // UacActions.refresh()
    // return null
    // console.error(`%c useUserAchievementCriteria is loading or empty`, `color: red`)
    // console.error(` state size:  ${UacState.userAchievementCriteria.size}`)
    return <p className="text-center">Loading Achievement Progress...</p>
  }

  if (!achievement) {
    console.error(`%c Achievement is null maybe it shouldn't be 🤔 `, 'color: red')
    return null
  }
  if (!userAchievement) {
    console.error(`%c UserAchievement is null maybe it shouldn't be 🤔  `, 'color: red')
    return null
  }

  const handleExpansionState = () => {
    console.log('handleExpansionState')
    setIsExpanded((prevState) => !prevState)
  }

  const handleTrashIconClick = () => {
    setIsModalOpen(true)
  }

  const handleConfirmAction = () => {
    if (!userAchievement) return
    removeUserAchievement(userAchievement.id!)
    setIsModalOpen(false)
  }

  if (achievementProgress === null) {
    console.error(`%c achievementProgress is null maybe it shouldn't be 🤔 `, 'color: red')
    return null
    return null
  }

  const renderProgressBars = (breakdown: { uacId: string; uacProgress: number }[]) => {
    const numberOfBars: number = breakdown.length
    const progressBars = breakdown.map((uac, index) => {
      const color: string = colorSets[numberOfBars][index]
      return <div key={`progressBreakdown${uac.uacId}+${index}`} className={`h-3 bg-[${color}] shadow-inner  `} style={{ width: `${uac.uacProgress / numberOfBars}%` }}></div>
    })
    return progressBars
  }
  //achievement, userAchievement, dismissable
  console.log(` achievement, userAchievement, dismissable :>> `, achievement, userAchievement, dismissable)
  return (
    <div
      className={`flex flex-col py-0.5 sm:py-1 px-2 sm:px-4 mt-0.5 md:mt-1 max-w-md mx-auto border border-teal rounded-md 
        shadow-md shadow-slate-300 overflow-hidden md:max-w-3xl space-y-1 sm:space-y-4 cursor-pointer`}
    >
      <div>
        <div className="relative">
          {isDismissable && (
            <div className="absolute top-0 right-0">
              <TrashIcon className="w-6 h-6 text-gray-500 opacity-50 hover:opacity-100" onClick={handleTrashIconClick} />
            </div>
          )}
        </div>
        <div className="flex items-center " onClick={handleExpansionState}>
          <div className="flex items-center space-x-2 sm:space-x-4 justify-between w-5/6">
            {isExpanded ? (
              <ChevronDownIcon className="h-4 sm:h-6 w-4 sm:w-6 text-gray-500 " />
            ) : (
              <ChevronDownIcon className="h-4 sm:h-6 w-4 sm:w-6 text-gray-500  transform rotate-180" />
            )}
            <img src={achievement.badgeImageUrl} alt="Achievement Badge" className={`w-24 sm:w-26 h-24 sm:h-26 mr-1 sm:mr-2    border border-slate-800 `} />
            <div className="w-2/3 md:w-3/4 relative">
              <h2 className="font-bold text-sm md:text-base lg:text-lg">{achievement.name}</h2>
              <p className="text-xs">{achievement.descriptionMd}</p>
              {isInTheFuture && (
                <div className="flex items-center justify-center w-full bg-gray-200 bg-opacity-50  ">
                  <p className="text-xs text-gray-900">Starts in {differenceInDays} Days</p>
                </div>
              )}
              {hasEnded && (
                <div className="flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-50 ">
                  <p className="text-xs text-gray-900">Ended on {new Date(userAchievement!.endDate!).toLocaleDateString()}</p>
                </div>
              )}
              <div className="mt-1 sm:mt-2 w-full relative">
                <div className="h-3 bg-gray-200  rounded-md overflow-clip border border-slate-400 overflow-hidden   shadow-inner   shadow-slate-300 flex">
                  {renderProgressBars(achievementProgress.breakdown)}
                </div>
                <div className={`absolute top-0 left-[${achievement.oneStarMinPercent}%] flex`}>
                  <div className="h-1 w-px bg-black"></div>
                  <StarIcon className={`h-3 w-3 text-gray-500 fill-current  ${achievementProgress.starRating < 1 ? `opacity-30` : ''}`} />
                </div>
                <div className={`absolute top-0 left-[${achievement.twoStarMinPercent}%] flex`}>
                  <div className="h-1 w-px bg-black"></div>
                  <StarIcon className={`h-3 w-3 text-gray-500 fill-current  ${achievementProgress.starRating < 2 ? `opacity-30` : ''}`} />
                </div>
                <div className={`absolute top-0 left-[${achievement.threeStarMinPercent}%] flex`}>
                  <div className="h-1 w-px bg-black"></div>
                  <StarIcon className={`h-3 w-3 text-gray-500 fill-current  ${achievementProgress.starRating < 3 ? `opacity-30` : ''}`} />
                </div>
              </div>
            </div>
          </div>
          <div className="flex space-x-1 sm:space-x-2 w-1/6">
            <StarIcon className={`h-4 sm:h-6 w-4 sm:w-6 text-gray-500 fill-current  ${achievementProgress.starRating < 1 ? `opacity-30` : ''}`} />
            <StarIcon className={`h-4 sm:h-6 w-4 sm:w-6 text-gray-500 fill-current  ${achievementProgress.starRating < 2 ? `opacity-30` : ''}`} />
            <StarIcon className={`h-4 sm:h-6 w-4 sm:w-6 text-gray-500 fill-current  ${achievementProgress.starRating < 3 ? `opacity-30` : ''}`} />
          </div>
        </div>
        <div className="transition-all duration-1000 ease-out overflow-hidden">
          <div className={`flex flex-col mt-0.5 mb-0.5 sm:mt-1 sm:mb-1 space-y-0.5 sm:space-y-1 ${isExpanded ? 'max-h-96' : 'h-0'}  `}>
            {isExpanded !== false &&
              achievement.achievementCriteria!.map((criteria, index) => {
                if (!achievement.achievementCriteria) return null
                const color: string = colorSets[achievement.achievementCriteria?.length][index]
                if (!criteria || !criteria.id) return null
                const uac = UacActions.findByAchievementCriteriaId(criteria.id)

                let userValue = uac?.value
                const uacId = uac?.id

                if (!userValue) userValue = 0
                console.log('uacId :>> ', uacId)
                const isInProgress = (userAchievement && userAchievement.status === 'in-progress' && !isInTheFuture && !hasEnded) ?? false

                return (
                  <AchievementCriteria
                    key={criteria.id}
                    acId={criteria.id}
                    uacId={uacId!}
                    color={color}
                    displayText={criteria.displayText}
                    congratsText={criteria.congratsText}
                    userAchievementCriteriaValue={userValue}
                    target={criteria.target}
                    isDerived={criteria.derived}
                    inProgress={isInProgress}
                  />
                )
              })}
          </div>
        </div>
        <ConfirmationModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onConfirm={handleConfirmAction}
          text={'Opting out of an achievement will result in the loss of all progress and can not be undone.'}
        />
      </div>
    </div>
  )
}
