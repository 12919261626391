import React, { useEffect, useReducer, useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { AuthContainer, Box, Button, Input, PasswordValidations } from 'shared/components';
import styles from './ResetPassword.module.scss';
import { FirebaseAuth } from 'services/firebase';
import { Validations } from 'shared/functions';
import { FormValidationError } from 'types';

interface FormState {
  email?: string;
  password?: string;
  confirmPassword?: string;
  loading?: boolean;
  errors?: FormValidationError;
}

type FORM_ACTIONS = {
  type: 'update';
  payload: FormState;
};

const initialState: FormState = {
  email: '',
  password: '',
  confirmPassword: '',
  loading: false,
  errors: {},
};

const reducer = (state: FormState, action: FORM_ACTIONS) => {
  switch (action.type) {
    case 'update': {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
};

export const ResetPassword: React.FC = () => {
  const location = useLocation();
  const [state, dispatch] = useReducer(reducer, initialState);
  const parsedLocation = queryString.parse(location.search);
  const { oobCode, continueUrl } = parsedLocation;
  const [showSuccess, setShowSuccess] = useState(false);
  const [showInvalidMessage, setShowInvalidMessage] = useState('');

  useEffect(() => {
    const verifyCode = async (code: string) => {
      try {
        console.log('oobCode :>> ', code);
        // returns user's email address if valid
        const result = await FirebaseAuth.verifyPasswordResetCode(code);
        dispatch({
          type: 'update',
          payload: {
            email: result,
          },
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (error.message) {
          setShowInvalidMessage(error.message);
        }
      }
    };
    if (oobCode) {
      verifyCode(oobCode as string);
    } else {
      setShowInvalidMessage('The action code is invalid. This can happen if the code is malformed, expired, or has already been used.');
    }
  }, [oobCode]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    dispatch({
      type: 'update',
      payload: {
        [name]: value,
        errors: {
          [name]: undefined,
        },
      },
    });
  };

  const onClickResetPassword = async () => {
    console.log('state :>> ', state);

    dispatch({
      type: 'update',
      payload: {
        loading: true,
        errors: {},
      },
    });

    if (confirmPassword !== password) {
      dispatch({
        type: 'update',
        payload: {
          loading: false,
          errors: {
            confirmPassword: 'Passwords do not match',
          },
        },
      });
      return;
    }

    const required = { password, confirmPassword };
    const newErrors = Validations.validateForm(required);
    if (Object.keys(newErrors).length > 0) {
      dispatch({
        type: 'update',
        payload: {
          loading: false,
          errors: newErrors,
        },
      });

      return;
    }

    try {
      await FirebaseAuth.confirmPasswordReset(oobCode as string, password);
      setShowSuccess(true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch({
        type: 'update',
        payload: {
          loading: false,
          errors: {
            general: error.message,
          },
        },
      });
    }
  };

  /**
   * Navigate to continue url or default to welcome screen
   * Deep links should pick up link on mobile device and ask to open in mobile app if installed
   */
  const onClickContinue = () => {
    if (continueUrl) {
      window.location.replace(continueUrl as string);
    } else {
      window.location.replace('/login');
    }
  };

  const { email = '', password = '', confirmPassword = '', loading, errors = {} } = state;

  const isDisabled = password.length === 0 || confirmPassword.length === 0;

  return (
    <AuthContainer>
      {showInvalidMessage ? (
        <>
          <p className={styles.headline}>Reset your password</p>
          <p className={styles.subHeading}>{showInvalidMessage}</p>
          <Button onClick={onClickContinue} containerStyle={styles.actionBtn} label="Back to login" />
        </>
      ) : showSuccess ? (
        <>
          <p className={styles.headline}>Password successfully reset.</p>

          <Button onClick={onClickContinue} containerStyle={styles.actionBtn} label="Continue to login" />
        </>
      ) : (
        <>
          <p className={styles.headline}>Reset your password</p>
          <p className={styles.subHeading}>Enter a new password, if you keep having trouble email us at info@leansquad.com</p>

          <Box className={styles.inputsContainer}>
            <Input
              containerStyle={styles.input}
              value={email}
              onChange={onChange}
              name="email"
              placeholder="Email"
              error={errors.email}
              disabled
            />

            <Input
              containerStyle={styles.input}
              value={password}
              onChange={onChange}
              name="password"
              placeholder="Password"
              error={errors.password}
              type="password"
            />
            <Input
              containerStyle={styles.input}
              value={confirmPassword}
              onChange={onChange}
              name="confirmPassword"
              placeholder="Confirm Password"
              error={errors.confirmPassword}
              type="password"
            />
            <PasswordValidations password={password} />
          </Box>
          <Box className={styles.footer}>
            {errors.general && <p className={`errorText ${styles.errorMessage}`}>{errors.general}</p>}
            <Button
              onClick={onClickResetPassword}
              loading={loading}
              containerStyle={styles.actionBtn}
              label="Reset Password"
              disabled={isDisabled}
            />
          </Box>
        </>
      )}
    </AuthContainer>
  );
};
