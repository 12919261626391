/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useState, useEffect, useCallback, useContext } from 'react'
import UserAchievementApi from '../services/api/userAchievements'
import { UserAchievement } from '../types'
import { AuthStateContext } from './AuthStateContext'
import { useUserAchievementCriteriaActions } from 'hooks/useUserAchievementCriteria'
import { useReportableUACs } from 'hooks/useReportableUACs'

// Create a Context object
export const UserAchievementContext = createContext({
  userAchievements: new Map<string, UserAchievement>(),
  userJoinedAchievement: (id: string): boolean => {
    return false
  },
  updateUserAchievement: (id: string, updatedAchievement: UserAchievement) => {},
  createUserAchievement: async (newAchievement: UserAchievement): Promise<UserAchievement | null> => {
    return null
  },
  userAchievementArray: [] as UserAchievement[],
  removeUserAchievement: async (id: string) => {},
  refresh: () => {},
  isLoading: false,
})
UserAchievementContext.displayName = 'UserAchievementContext'
// Provider component
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const UserAchievementProvider = ({ children }: any) => {
  const [userAchievements, setUserAchievements] = useState(new Map<string, UserAchievement>())
  const [userAchievementArray, setUserAchievementArray] = useState<UserAchievement[]>([])
  const { state: authState } = useContext(AuthStateContext)
  const { user } = authState
  const UacContext = useUserAchievementCriteriaActions()
  const [isLoading, setIsLoading] = useState(false)

  const { refetchReportableUACs } = useReportableUACs()
  const refresh = async () => {
    console.log(`%c Refreshing User Achievements`, 'color: red')
    setIsLoading(true)

    const map = new Map<string, UserAchievement>()

    const data = await UserAchievementApi.getAll()
    data.forEach((userAchievement) => {
      if (!userAchievement.id) return
      map.set(userAchievement.id, userAchievement)
    })

    setUserAchievements(map)
    setUserAchievementArray(data)

    setIsLoading(false)
  }

  useEffect(() => {
    if (user) {
      refresh()
    }
  }, [user])

  const userJoinedAchievement = useCallback(
    (id: string) => {
      let joinStatus = false
      userAchievements.forEach((userAchievement) => {
        if (userAchievement.achievementId === id) {
          joinStatus = true
        }
      })
      return joinStatus
    },
    [userAchievements],
  )

  const updateUserAchievement = useCallback(async (id: string, updatedAchievement: UserAchievement) => {
    try {
      const updated = await UserAchievementApi.update(updatedAchievement)
      setUserAchievements((prev) => new Map(prev).set(id, updated))
    } catch (error) {
      console.error('Error updating user achievement: ', error)
    }
  }, [])

  const createUserAchievement = async (newAchievement: UserAchievement): Promise<UserAchievement | null> => {
    try {
      console.log('%c Creating UACs', 'color: purple')

      const created = await UserAchievementApi.create(newAchievement)
      setUserAchievements((prev) => new Map(prev).set(created.id!, created))

      console.log(`%c Created User Achievement: ${JSON.stringify(created)}`, 'color: orange')
      console.log(`%c Refreshing User Achievement Criteria`, 'color: purple')
      setUserAchievementArray((prev) => {
        const newArray = prev.filter((ua) => ua.id !== created.id)
        return newArray
      })
      await refresh()
      await UacContext.refresh()
      console.log('%c Refreshing Reportable UACs', 'color: purple')
      await refetchReportableUACs()
      return created
    } catch (error) {
      console.error('Error creating user achievement: ', error)
      return null
    }
  }

  const removeUserAchievement = async (id: string) => {
    try {
      await UserAchievementApi.remove(id)
      setUserAchievements((prev) => {
        const newMap = new Map(prev)
        newMap.delete(id)
        return newMap
      })
      setUserAchievementArray((prev) => {
        const newArray = prev.filter((ua) => ua.id !== id)
        return newArray
      })
      await refresh()
      await UacContext.refresh()
    } catch (error) {
      console.error('Error removing user achievement: ', error)
    }
  }

  return (
    <UserAchievementContext.Provider
      value={{ userAchievements, updateUserAchievement, createUserAchievement, userJoinedAchievement, userAchievementArray, removeUserAchievement, refresh, isLoading }}
    >
      {children}
    </UserAchievementContext.Provider>
  )
}
