import { useState, useEffect, useRef, useCallback } from 'react';
import { Workout } from 'types';
import videojs, { VideoJsPlayer } from 'video.js';
import 'videojs-mux';
import 'videojs-playlist';
import 'videojs-playlist-ui';
import 'videojs-overlay';
import 'videojs-contrib-quality-levels';
import 'videojs-max-quality-selector';
import 'videojs-overlay/dist/videojs-overlay.css';
import 'videojs-playlist-ui/dist/videojs-playlist-ui.css';
import usePrevious from './usePrevious';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import airplay from '@silvermine/videojs-airplay';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import chromecast from '@silvermine/videojs-chromecast';

airplay(videojs);
chromecast(videojs, { preloadWebComponents: true });

type VideoHookType = {
  playerRef: React.MutableRefObject<VideoJsPlayer | null>;
  videoRef: React.MutableRefObject<HTMLVideoElement | null>;
  showWorkoutComplete: boolean;
};

type Source = {
  src: string;
  type: string;
};

type PlaylistSource = {
  workout: Workout;
  name: string;
  sources: Source[];
  poster: string;
  thumbnail: Array<{ src: string }>;
};

type Overlay = {
  showBackground: boolean;
  content: string;
  align: string;
  class: string;
  start: string | number;
  end: string | number;
};

export const useVideo = (workout?: Workout, withMusic?: boolean): VideoHookType => {
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const playerRef = useRef<VideoJsPlayer | any>(null)
  const prevWorkout = usePrevious(workout, undefined)
  const [showWorkoutComplete, setShowWorkoutComplete] = useState(false)
  const videoUrl = withMusic && workout?.signedVideoWithMusicUrl ? workout?.signedVideoWithMusicUrl : workout?.signedVideoUrl

  const playlistSources: PlaylistSource[] = workout
    ? [
        {
          workout,
          name: workout.title,
          sources: [
            {
              src: videoUrl || '',
              type: 'application/x-mpegURL',
            },
          ],
          poster: workout.previewImageUrl,
          thumbnail: [
            {
              src: workout.previewImageUrl,
            },
          ],
        },
      ]
    : []

  // Check for finisher
  if (workout && workout.finisher?.workout) {
    const finisherSource: PlaylistSource = {
      workout: workout.finisher.workout,
      name: workout.finisher.workout.title,
      sources: [
        {
          src: workout.finisher.workout.signedVideoUrl || '',
          type: 'application/x-mpegURL',
        },
      ],
      poster: workout.finisher?.workout?.previewImageUrl,
      thumbnail: [
        {
          src: workout.previewImageUrl,
        },
      ],
    }
    playlistSources.push(finisherSource)
  }

  const setupEventListeners = useCallback(() => {
    console.log('setting up video event listeners :>> ', playlistSources)
    // Automatically cleaned up when player disposes
    if (playerRef.current) {
      playerRef.current.on('timeupdate', () => {
        console.log('playerRef.current :>> ', playerRef.current)
        const currentTime = playerRef.current?.currentTime()
        const duration = playerRef.current?.duration()
        if (currentTime && duration) {
          const percentageProgress = (currentTime / duration) * 100
          console.log('percentageProgress :>> ', percentageProgress)
          if (percentageProgress >= 30) {
            setShowWorkoutComplete(true)
          }
        }
      })
      playerRef.current.on('seeking', () => {
        console.log('playerRef seeking:>> ', playerRef.current?.seeking())
      })
      playerRef.current.on('ended', () => {
        console.log('playerRef ended:>> ', playerRef.current?.ended())
      })
      playerRef.current.on('playlistchange', (args: any) => {
        console.log('playerRef playlistchange:>> ', args)
      })
      playerRef.current.on('duringplaylistchange', (args: any) => {
        console.log('playerRef duringplaylistchange:>> ', args)
      })
      playerRef.current.on('playlistitem', (_event: any, video: PlaylistSource) => {
        console.log('playerRef playlistitem video:>> ', video)

        if (video && video.workout) {
          playerRef.current?.mux.emit('videochange', {
            player_init_time: Date.now(), // ex: 1451606400000
            video_id: video.workout.id, // ex: 'abcd123'
            video_title: video.workout.title, // ex: 'My Great Video'
            video_series: video.workout.type.title, // ex: 'Weekly Great Videos'
            video_stream_type: 'on-demand', // 'live' or 'on-demand'
            // ...
          })
          const overlays: Overlay[] = [
            {
              showBackground: false,
              content: `<h1>${video.workout.title}</h1>`,
              align: 'top-left',
              class: 'videoOverlay',
              start: 0,
              end: 3,
            },
          ]
          if (video.workout.finisher) {
            overlays.push({
              showBackground: false,
              content: `<h1>Up Next: ${video.workout.finisher?.title}</h1>`,
              align: 'bottom-right',
              class: 'videoOverlay',
              start: 'ended',
              end: Infinity,
            })
          }
          playerRef.current?.overlay({
            overlays: overlays,
          })
        }
      })
    }
  }, [])

  useEffect(() => {
    // console.log('workout :>> ', workout);
    // Workout video does not change between Workout and Finisher.
    if (videoRef.current && prevWorkout !== workout) {
      const video = videoRef.current
      // Can only be initialized once.
      if (workout && workout.signedVideoUrl) {
        if (playerRef.current) {
          playerRef.current?.playlist(playlistSources)
        } else {
          playerRef.current = videojs(video, {
            preload: 'auto',
            techOrder: ['chromecast', 'html5'],
            controlBar: {
              pictureInPictureToggle: false,
            },
            plugins: {
              chromecast: {
                addButtonToControlBar: true, // defaults to `true`
                buttonPositionIndex: 1, // defaults to `0`
              },
              airPlay: {
                addButtonToControlBar: true, // defaults to `true`
                buttonPositionIndex: 1, // defaults to `0`
              },
              playlist: playlistSources,
              // playlistUi: {},
              qualityLevels: {},
              overlay: {
                content: ``,
                showBackground: false,
              },
              mux: {
                debug: false,
                data: {
                  env_key: process.env.REACT_APP_MUX_ENV_KEY, // required

                  // Metadata
                  player_name: 'Web Video Player', // ex: 'My Main Player'
                  // Rest are set on videochange from playlist
                },
              },
            },
          })

          playerRef.current.chromecast()
          playerRef.current.playlist.autoadvance(3)

          playerRef.current?.chromecast()
          playerRef.current?.playlist.autoadvance(3)

          playerRef.current?.maxQualitySelector({
            defaultQuality: 2,
            displayMode: 1,
            minHeight: 480, // Do not list any resolutions smaller than 480p.
          }),
            console.log(`playerRef.current`, playerRef.current)

          setupEventListeners()
        }
      }
    }
  }, [workout, prevWorkout, setupEventListeners, playlistSources])

  useEffect(() => {
    return () => {
      if (playerRef.current) {
        const playerRefCurr = playerRef.current
        playerRefCurr.dispose()
        videoRef.current = null
        playerRef.current = null
      }
    }
  }, [playerRef])

  return { videoRef, playerRef, showWorkoutComplete }
}
