import React, { useEffect, useState, useRef } from 'react'
import styles from './ProgressBar.module.scss'

type Props = {
  size: number
  progress: number
  strokeWidth: number
  centerText: string
}

export const ProgressBar: React.FC<Props> = ({ size, progress, strokeWidth, centerText }) => {
  const [offset, setOffset] = useState(0)
  const circleRef = useRef(null)

  const center = size / 2
  const radius = size / 2 - strokeWidth / 2
  const circumference = 2 * Math.PI * radius

  useEffect(() => {
    const progressOffset = ((100 - progress) / 100) * circumference
    setOffset(progressOffset)

    // circleRef.current.style = 'transition: stroke-dashoffset 850ms ease-in-out';
  }, [setOffset, progress, circumference, offset])

  return (
    <>
      <svg className={styles.svg} width={size} height={size}>
        <defs>
          <linearGradient id="linearColors" x1="1" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#E42A6C"></stop>
            <stop offset="50%" stopColor="#C393FF"></stop>
            <stop offset="75%" stopColor="#C393FF"></stop>
            <stop offset="100%" stopColor="#C393FF"></stop>
          </linearGradient>
        </defs>
        <circle className={styles.svgCircleBg} cx={center} cy={center} r={radius} strokeWidth={strokeWidth} />
        <circle
          className={styles.svgCircle}
          ref={circleRef}
          cx={center}
          cy={center}
          r={radius}
          stroke="url(#linearColors)"
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          transform={`rotate(-90 ${center} ${center})`}
          strokeLinecap="round"
        />
        <text x="50%" y="50%" dy=".3em" className={styles.svgCircleText}>
          {centerText}
        </text>
      </svg>
    </>
  )
}
