import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Workout } from '../types/index'
import { ReactComponent as Play } from 'assets/icons/play.svg'
import { useVideoPlayerContext } from 'shared/components'

type Props = {
  finisher: Workout
  parentWorkout?: Workout
  useContext: boolean
  size?: 'small' | 'large'
  className?: string
}

const FlexFinisher = ({ finisher, parentWorkout, useContext, className, size = 'small' }: Props) => {
  const context = useVideoPlayerContext()
  const history = useHistory()

  const handlePlayClick = useCallback(() => {
    if (useContext) {
      context.setActiveWorkout(finisher)
    }
    if (parentWorkout) {
      history.push(`/workouts/${finisher.id}/overview?challengeWorkout=${parentWorkout.id}`, {
        workout: finisher,
      })
    } else {
      history.push(`/workouts/${finisher.id}/overview`, {
        workout: finisher,
      })
    }
  }, [])

  return (
    <div className={`bg-slate-800  shadow-md p-4 mb-2 text-slate-100 rounded-xl snap-center snap-always ${size === 'small' ? 'w-[320px] ' : 'w-[500px] '} ${className}`}>
      <div className={`grid grid-cols-3 gap-2  ${size === 'small' ? 'w-[300px]' : 'w-[480px]'}`}>
        <div className=" shadow-md">
          <img src={finisher.previewImageUrl} className={'object-cover rounded-md h-24 w-24'} />
        </div>

        <div className={'ml-4 flex col-span-2 flex-col'}>
          <div className="h-14">
            <p className={'font-bold text-lg uppercase  leading-5 '}>{finisher.title}</p>
          </div>
          <div className="flex">
            <div className="w-4/5">
              <p className={'text-sm mt-3 text-slate-300'}>{finisher.duration.title} | Optional</p>
            </div>
            <button onClick={handlePlayClick} className={' flex mt-2  h-8 w-8 rounded-full justify-center items-center bg-coral '}>
              <Play />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FlexFinisher
