import React, { createContext, useReducer, useEffect, useRef, useContext } from 'react'
import * as Sentry from '@sentry/react'
import { AuthState, AuthActionTypes, StorageObj, User, RequestError } from 'types'
import { firebase } from 'services/firebase'
import { fetchingUserFailAction, fetchingUserSuccessAction, resetUserStateAction } from './actions/authStateActions'
import { UserAPI } from 'services/api'
import { LocalStorage } from 'shared/functions'
import { useMutation, useQuery } from 'react-query'
import { NotyfContext } from './NotyfContext'
import { CreateUserOptions } from 'services/api/types'

const { auth } = firebase

const initialState: AuthState = {
  user: undefined,
  isFetching: true,
  error: undefined,
}

const reducer = (state: AuthState = initialState, action: AuthActionTypes) => {
  switch (action.type) {
    case 'FETCHING_USER':
      return {
        ...state,
        user: undefined,
        isFetching: true,
        error: undefined,
      }

    case 'FETCHING_USER_SUCCESS':
      return {
        ...state,
        user: action.user,
        isFetching: false,
        error: undefined,
      }

    case 'FETCHING_USER_FAIL':
      return {
        ...state,
        user: undefined,
        isFetching: false,
        error: action.error,
      }

    case 'RESET_STATE':
      return { ...initialState, isFetching: false }

    default:
      return state
  }
}

const defaultDispatch: React.Dispatch<AuthActionTypes> = () => initialState

export const AuthStateContext = createContext({
  state: initialState,
  dispatch: defaultDispatch,
})
AuthStateContext.displayName = 'AuthStateContext'

export const AuthStateProvider: React.FC<{ children: React.ReactNode }> = ({ children }: any) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const unsubscriber = useRef<firebase.Unsubscribe | null>(null)
  const fetchingUser = useRef(false)
  const notyf = useContext(NotyfContext)

  const { refetch: refetchUser } = useQuery<User>('user', UserAPI.getUser, {
    enabled: false,
    retry: false,

    onSuccess: (res) => {
      dispatch(fetchingUserSuccessAction(res))
      fetchingUser.current = false
    },
  })

  const { mutate: mutateCreateUser } = useMutation((createOptions: CreateUserOptions) => UserAPI.createUser(createOptions), {
    onSuccess: (res) => {
      LocalStorage.removeItem('createAccount')
      dispatch(fetchingUserSuccessAction(res))
    },
    onError: (error: RequestError) => {
      notyf.error(error.message)
      dispatch(fetchingUserFailAction(error))
    },
  })

  const fetchUser = async (user: firebase.User) => {
    try {
      await refetchUser({ throwOnError: true })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.status && error.status === 404) {
        // Get First/Last from local storage;
        const { fullName } = (LocalStorage.getItem('createAccount', null) as StorageObj) || {}
        let firstName = ''
        let lastName = ''

        if (fullName) {
          const names = fullName.split(' ')
          firstName = names[0]
          lastName = names[names.length - 1]
        } else {
          // Try and get user's name from Firebase Display Name if available
          const authCurrentUser = auth().currentUser
          if (authCurrentUser && authCurrentUser.displayName) {
            const nameSplit = authCurrentUser.displayName.split(' ')
            firstName = nameSplit[0] || ''
            lastName = nameSplit[1] || ''
          }
        }
        mutateCreateUser({
          email: user.email || '',
          first_name: firstName || '',
          last_name: lastName || '',
        })
      } else {
        dispatch(fetchingUserFailAction(error))
      }
      fetchingUser.current = false
    }
  }

  useEffect(() => {
    // Start Auth Listener
    if (!unsubscriber.current) {
      unsubscriber.current = auth().onAuthStateChanged(
        (user) => {
          if (user) {
            if (!fetchingUser.current) {
              Sentry.setUser({
                id: user.uid,
                email: user.email || '',
              })
              fetchUser(user)
            }
          } else {
            Sentry.configureScope((scope) => scope.setUser(null))
            dispatch(resetUserStateAction())
          }
        },
        (error) => {
          dispatch(resetUserStateAction())
        },
      )
    }
  }, [])

  useEffect(() => {
    return () => {
      // Stop Auth Listener
      if (unsubscriber.current) {
        unsubscriber.current()
      }
    }
  }, [])

  return <AuthStateContext.Provider value={{ state, dispatch }}>{children}</AuthStateContext.Provider>
}

export const AuthStateConsumer = AuthStateContext.Consumer
