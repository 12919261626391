import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Achievement, User, UserAchievement } from '../../types/index'
import { AuthStateContext } from 'context'
import { useUserAchievements } from 'hooks/useUserAchievements'
import { useAchievements } from 'hooks/useAchievements'
import { StarIcon } from '@heroicons/react/20/solid'
import AchievementProgress from '../../components/Achievements/AchievementProgress'
import { OptInAchievement } from 'components/Achievements/OptInAchievement'

const renderName = (user: User | undefined) => {
  if (!user) {
    return null
  }
  return (
    <div className="">
      <h1 className="text-2xl font-extrabold ">
        {user.firstName} {user.lastName}&apos;s Leanchievements!
      </h1>
    </div>
  )
}

const MY_ACHIEVEMENTS = 'myAchievements'
const AVAILABLE_ACHIEVEMENTS = 'availableAchievements'

const AchievementOverview = () => {
  const { userAchievements, createUserAchievement, userJoinedAchievement, isLoading: UaIsLoading } = useUserAchievements()
  const { state: authState, dispatch: authDispatch } = useContext(AuthStateContext)
  const { user } = authState
  const { findById, data: achievements, getAvailabledAchievements } = useAchievements()
  const [inProgressAchievements, setInProgressAchievements] = useState<UserAchievement[]>([])
  const [completedAchievements, setCompletedAchievements] = useState<UserAchievement[]>([])
  const [countOfMin1Star, setCountOfMin1Star] = useState(0)
  const [tabSelected, setTabSelected] = useState(MY_ACHIEVEMENTS)
  const [availableAchievements, setAvailableAchievements] = useState<Achievement[]>()

  // useffect on first load if the user has no achievements and it's done loading user achievements set AvailableAchievements to the tabSelected
  useEffect(() => {
    if (!UaIsLoading && userAchievements.size === 0) {
      setTabSelected(AVAILABLE_ACHIEVEMENTS)
    }
  }, [userAchievements, UaIsLoading])

  const filterInProgressAchievements = () => {
    if (userAchievements.size <= 0) {
      console.log('Early exit: ', { userAchievements, UaIsLoading })
      // Things might be removed so set values to empty
      // if they aren't already empty
      if (inProgressAchievements.length > 0) setInProgressAchievements([])
      if (completedAchievements.length > 0) setCompletedAchievements([])
      if (countOfMin1Star > 0) setCountOfMin1Star(0)
      return
    }
    console.log('Pre Filtering achievements', { userAchievements }, { UaIsLoading })

    const inProgress: UserAchievement[] = []
    const completed: UserAchievement[] = []
    let min1StarCount = 0

    console.log('Starting to filter achievements')

    Array.from(userAchievements.values()).forEach((userAchievement) => {
      console.log('Current userAchievement: ', userAchievement)

      if (userAchievement.status === 'in-progress' && !userAchievement.completed) {
        console.log('Adding to inProgress: ', userAchievement)
        inProgress.push(userAchievement)
      } else if (userAchievement.completed) {
        console.log('Adding to completed: ', userAchievement)
        completed.push(userAchievement)
      }

      if ((userAchievement.starRating ?? 0) >= 1) {
        console.log('Incrementing min1StarCount')
        min1StarCount++
      }
    })

    console.log('Sorting inProgress and completed arrays')

    inProgress.sort((a, b) => new Date(b.startDate!).getTime() - new Date(a.startDate!).getTime())
    completed.sort((a, b) => new Date(b.endDate!).getTime() - new Date(a.endDate!).getTime())

    console.log('Setting state variables')

    setInProgressAchievements(inProgress)
    setCompletedAchievements(completed)
    setCountOfMin1Star(min1StarCount)

    console.log('State variables set: ', { inProgress, completed, min1StarCount })
  }

  // if current date is before October 1st
  const beforeLaunch = new Date() < new Date('2021-10-01T00:00:00.000Z')
  const beforeLaunchMessage = 'Leanchievements will be available on October 1st, 2021'
  const afterLaunchMessage = 'There are no more Leanchievements available at this time. Stay tuned for more!'

  // Filter UserAchievements
  useEffect(() => {
    if (UaIsLoading) return

    filterInProgressAchievements()
  }, [userAchievements, UaIsLoading])

  // Refresh Available Achievements
  useEffect(() => {
    if (!achievements) return
    const availableAchievements = getAvailabledAchievements()
    if (!availableAchievements) return
    availableAchievements.sort((a, b) => {
      if (!a.publishDate || !b.publishDate) return 0
      return new Date(b.publishDate).getTime() - new Date(a.publishDate).getTime()
    })
    setAvailableAchievements(availableAchievements)
  }, [achievements, userAchievements])

  // Opt in to Achievement
  const optInToAchievement = async (id: string) => {
    const newAchievement = {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      achievementId: id!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      userId: authState.user!.id!,
      status: 'in-progress',
      starRating: 0,
      completed: false,
    }
    const created = await createUserAchievement(newAchievement)
  }

  if (!user || !userAchievements || !achievements || !availableAchievements) {
    return <p>loading user or UA array</p>
  }
  console.log({ inProgressAchievements })

  const renderTotalAchievements = (totalLeanChievements: number) => {
    return (
      <div className="flex flex-col items-center mt-3 ">
        <p className="text-5xl font-extrabold">{totalLeanChievements}</p>
        <p className="text-lg font-extrabold">Total Leanchievements</p>
      </div>
    )
  }

  const render1StarCount = () => {
    let count = 0
    userAchievements.forEach((userAchievement) => {
      if (userAchievement.starRating === 1) {
        count++
      }
    })
    return (
      <div className="flex flex-col items-center">
        <StarIcon className="h-5 w-5 text-yellow-500" />
        <p className="text-lg font-extrabold">{count}</p>
      </div>
    )
  }
  const render2StarCount = () => {
    let count = 0
    userAchievements.forEach((userAchievement) => {
      if (userAchievement.starRating === 2) {
        count++
      }
    })
    return (
      <div className="flex flex-col items-center">
        <div className="flex flex-row ">
          <StarIcon className="h-5 w-5 text-yellow-500" />
          <StarIcon className="h-5 w-5 text-yellow-500" />
        </div>
        <p className="text-lg font-extrabold">{count}</p>
      </div>
    )
  }
  const render3StarCount = () => {
    let count = 0
    userAchievements.forEach((userAchievement) => {
      if (userAchievement.starRating === 3) {
        count++
      }
    })
    return (
      <div className="flex flex-col items-center">
        <div className="flex flex-row ">
          <StarIcon className="h-5 w-5 text-yellow-500" />
          <StarIcon className="h-5 w-5 text-yellow-500" />
          <StarIcon className="h-5 w-5 text-yellow-500" />
        </div>
        <p className="text-lg font-extrabold">{count}</p>
      </div>
    )
  }

  return (
    <div className="w-full max-w-3xl text-center ">
      <div className="bg-gradient-to-br from-[#036b82] to-[#47bbca] text-white p-6 rounded-lg border-4 border-slate-200 shadow-xl mb-4">
        <div className="text-center">
          <h1 className="text-2xl font-extrabold mb-4 text-white underline tracking-tight"> Leanchievements!</h1>
          <p className="text-base leading-relaxed">
            Level up your LEANNESS with <span className="underline">LEANCHIEVEMENTS</span>. Experience a multi-faceted approach to achieving your health goals!
          </p>
          <p className="text-base leading-relaxed mt-2">
            Leanchievements are <span className="font-bold">supercharged</span> achievements for you to complete. Each with multiple requirements that blend self-reporting and
            in-app accomplishments!
          </p>
          <p className="text-base leading-relaxed mt-2">
            Complete all the requirements and earn 3 stars!
            <span className="inline-flex ml-2">
              <StarIcon className="h-6 w-6  text-yellow-400" />
              <StarIcon className="h-6 w-6  ml-1 text-yellow-400" />
              <StarIcon className="h-6 w-6  ml-1 text-yellow-400" />
            </span>
          </p>
        </div>
      </div>

      {renderName(user)}
      {renderTotalAchievements(userAchievements.size)}
      <div className="flex   mt-3 max-w-3xl  ">
        <div className="flex flex-col  w-1/3">{render1StarCount()}</div>
        <div className="flex flex-col  w-1/3">{render2StarCount()}</div>
        <div className="flex flex-col  w-1/3">{render3StarCount()}</div>
      </div>

      <div className="max-w-3xl w-full flex mt-7 justify-center space-x-0 shadow-lg">
        <button
          className={`w-1/2 px-4 py-2  hover:bg-cyan-600 text-white border focus:outline-none focus:ring-2 focus:ring-cyan-300 ${
            tabSelected === MY_ACHIEVEMENTS ? ' border-slate-600  bg-cyan-700' : ' bg-cyan-500 border-slate-300'
          }`}
          onClick={() => {
            setTabSelected(MY_ACHIEVEMENTS)
          }}
        >
          My Leanchievements
        </button>
        <button
          className={`w-1/2 px-4 py-2 hover:bg-cyan-600 text-white border focus:outline-none focus:ring-2 focus:ring-cyan-300 ${
            tabSelected === AVAILABLE_ACHIEVEMENTS ? ' border-slate-600  bg-cyan-700' : ' bg-cyan-500 border-slate-300'
          }`}
          onClick={() => {
            setTabSelected(AVAILABLE_ACHIEVEMENTS)
          }}
        >
          Available Leanchievements
        </button>
      </div>
      <div className="mt-7">
        {tabSelected === MY_ACHIEVEMENTS && (
          <div className="">
            <div className="">
              <h1 className="text-2xl font-extrabold ">In Progress</h1>
            </div>
            {inProgressAchievements.map((userAchievement) => {
              const achievement = findById(userAchievement.achievementId)
              if (UaIsLoading) return <p>loading</p>
              if (!userAchievements) return <p>loading</p>
              if (!achievement) return <p>loading</p>
              return <AchievementProgress key={userAchievement.achievementId} achievement={achievement} userAchievement={userAchievement} />
            })}
            {inProgressAchievements.length === 0 && <p className="text-lg   font-extralight ">You have no Active Leanchievements in progress. Go Get Some! </p>}

            <div className="mt-7">
              <div className="">
                <h1 className="text-2xl font-extrabold ">Completed</h1>
              </div>
              {completedAchievements.length > 0 &&
                completedAchievements.map((userAchievement) => {
                  const achievement = findById(userAchievement.achievementId)
                  if (!achievement) return null
                  return <AchievementProgress key={userAchievement.achievementId} achievement={achievement} userAchievement={userAchievement} />
                })}
              {completedAchievements.length === 0 && <p className="text-lg   font-extralight ">No Completed Leanchievements to display</p>}
            </div>
          </div>
        )}
        {tabSelected === AVAILABLE_ACHIEVEMENTS && (
          <div className="">
            {inProgressAchievements.length >= 3 && (
              <p className="">You can only join 3 Leanchievements at a time. You must complete or remove an Leanchievements to join another.</p>
            )}
            {availableAchievements &&
              availableAchievements.map((achievement) => {
                if (userJoinedAchievement(achievement.id!)) return null
                return (
                  <div key={achievement.id} className="mt-3">
                    <OptInAchievement
                      achievement={achievement}
                      onDismiss={() => {
                        ;() => console.log('dismissed')
                      }}
                      onOptIn={async () => {
                        await optInToAchievement(achievement.id!)
                      }}
                    />
                  </div>
                )
              })}
            {
              // If there are no available achievements, show a message
              beforeLaunch && availableAchievements.length === 0 && <p className="">{beforeLaunchMessage}</p>
            }
            {!beforeLaunch && availableAchievements.length === 0 && <p className="">{afterLaunchMessage}</p>}
          </div>
        )}
      </div>
    </div>
  )
}

export { AchievementOverview }
