import queryString from 'querystring'
import { FiltersQuery, Workout, WorkoutClass, WorkoutFavorites, WorkoutFilterOptions, WorkoutType } from 'types'
import { get, post, del } from './helpers'

import { AddToWorkoutFavoritesBody, RemoveFromWorkoutFavoritesBody, WorkoutClassesResponse, WorkoutFavoritesResponse, WorkoutResponse, WorkoutTypesResponse } from './types'

export const getWorkoutTypes = async (): Promise<WorkoutType[]> => {
  const response = await get<WorkoutTypesResponse>(`/workouts/types`)

  return response
}

export const getWorkoutClassTypes = async (): Promise<WorkoutClass[]> => {
  const response = await get<WorkoutClassesResponse>(`/workouts/classTypes`)

  return response
}

type valueof<T> = T[keyof T]
type FilterQueryValue = valueof<FiltersQuery>

const formatFiltersForQuery = (filters: FiltersQuery, typeOptions?: WorkoutType[]) => {
  const formattedFilters: Record<string, string | number> = {}

  ;(Object.keys(filters) as Array<keyof FiltersQuery>).forEach((key) => {
    const filter: FilterQueryValue = filters[key]
    if (filter) {
      if (key === 'type') {
        if (filter !== 'all' && filter !== 'favorites') {
          const foundType = typeOptions?.find((item) => item.slug === filter)
          if (foundType) {
            formattedFilters.type = foundType?.id
          }
        }
        if (filter === 'favorites') {
          formattedFilters.favorites = 'true'
        }
      } else {
        formattedFilters[key] = `[${filter.toString()}]`
      }
    }
  })

  return formattedFilters
}

export const getWorkouts = async (filters?: FiltersQuery | null, typeOptions?: WorkoutType[]): Promise<Workout[]> => {
  console.log('gettingWorkouts with filters:>> ', filters)
  const formattedFilters = filters ? formatFiltersForQuery(filters, typeOptions) : null
  console.log('formatFiltersForQuery :>> ', formattedFilters)
  const filtersAsString = formattedFilters ? queryString.stringify(formattedFilters as Record<string, string>) : ''
  const response = await get<WorkoutResponse>(`/workouts/filters?${filtersAsString}`)
  console.log('gettingWorkouts :>> ', response)

  return response
}

export const getWorkoutById = async (id: string): Promise<Workout> => {
  const response = await get<Workout>(`/workouts/${id}`)

  return response
}

export const getWorkoutFavorites = async (): Promise<WorkoutFavorites[]> => {
  const response = await get<WorkoutFavoritesResponse>(`/workouts/favorites`)
  return response
}

export const getAllWorkoutFilterOptions = async (): Promise<WorkoutFilterOptions> => {
  const response = await get<WorkoutFilterOptions>(`/workouts/filter-options`)
  return response
}

export const addToFavorites = async (body: AddToWorkoutFavoritesBody): Promise<WorkoutFavorites[]> => {
  const response = await post<WorkoutFavoritesResponse>(`/workouts/favorites`, body)
  return response
}

export const removeFromFavorites = async (body: RemoveFromWorkoutFavoritesBody): Promise<WorkoutFavorites[]> => {
  const response = await del<WorkoutFavoritesResponse>(`/workouts/favorites`, body)
  return response
}
