import { AuthStateContext } from 'context';
import React, { useContext, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { authRoutes } from 'router';
import { Box, LoadingIndicator } from 'shared/components';
import { PreferenceUtils, SubscriptionUtils } from 'shared/functions';
import styles from './AuthLayout.module.scss';

export const AuthLayout: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const { state: authState } = useContext(AuthStateContext);
  const { isFetching, user } = authState;

  useEffect(() => {
    const { pathname } = location;

    if (!isFetching) {
      if (user) {
        const { subscription, preferences } = user;

        if (pathname === '/welcome-email' || pathname.includes('app-redirect')) {
          return;
        }

        // Check here for subscription status being valid.
        if (pathname.includes('/sign-up/')) {
          return;
        } else if (!SubscriptionUtils.isValidSubscription(subscription) && pathname !== '/subscription' && pathname !== '/sign-up') {
          history.replace('/subscription');
          return;
        }

        // User has active subscription, should not be allowed in auth subscription page.
        if (SubscriptionUtils.isValidSubscription(subscription)) {
          // Check if user has all required questionnaire answered
          if (!PreferenceUtils.hasRequiredQuestionnaire(preferences) && pathname !== '/get-started') {
            history.replace('/get-started');
            return;
          }

          if (pathname !== '/get-started') {
            // Navigates to different Layout
            history.replace('/today');
            return;
          }
        }
      }

      if (!user) {
        if (pathname === '/subscription' || pathname === '/get-started') {
          history.replace('/sign-up');
        }
      }
    }
  }, [user, isFetching, location]);

  if (isFetching) {
    return <LoadingIndicator />;
  }

  return (
    <Box className={`${styles.container} bg-blue-400  min-h-screen `}>
      <Switch>
        {authRoutes.map(({ path, name, component, exact }) => (
          <Route path={path} key={name} component={component} exact={exact} />
        ))}
        <Redirect to="/sign-up" />
      </Switch>
    </Box>
  );
};
