import React, { useContext, useEffect, useState, useMemo } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { NotyfContext } from 'context/NotyfContext'

import { AuthStateContext } from '../../../context/AuthStateContext'
import { EnvelopeIcon } from '@heroicons/react/20/solid'
import { UserAPI, ChallengeAPI, SubscriptionsAPI } from 'services/api'
import { useForm } from 'react-hook-form'
import ChallengeForm from './ChallengeForm'
import * as AdminApi from '../../../services/api/admin'
import { dayjs } from 'shared/functions'
import { AdminUserAchievements } from './AdminUserAchievements'

const AdminUser = () => {
  const { state: authState } = useContext(AuthStateContext)
  const [userEmail, setUserEmail] = useState('')

  const [userChallenge, setUserChallenge] = useState('')
  const [defferedUserEmail, setDifferedUserEmail] = useState('')
  const onSubmit = (data: any) => console.log(data)
  const queryClient = useQueryClient()
  const notyf = useContext(NotyfContext)

  const [createUserChallenge, setCreateUserChallenge] = useState(false)

  const { data: challenges, isFetching: isFetchingChallenges } = useQuery(
    'adminChallenges',
    async () => {
      const res = await ChallengeAPI.getAllChallenges()
      res.reverse()
      return res
    },
    {
      refetchOnWindowFocus: false,
    },
  )

  const {
    data: queriedUser,
    refetch,
    isError,
    isRefetching,
  } = useQuery(
    ['adminUserQueryResult'],
    async () => {
      return await UserAPI.getUserByEmail(userEmail)
    },
    {
      cacheTime: 0,
      retry: false,
      refetchOnMount: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      enabled: false, //disable the query:
    },
  )

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: 'onBlur',
  })

  // Query to get the user subscription info
  const {
    data: adminUserSubscriptionData,
    refetch: refetchUserSubscription,
    isError: userSubscriptionIsError,
    isRefetching: userSubscriptionIsRefetching,
  } = useQuery(
    ['adminUserQueryUserSubscriptionResult'],
    async () => {
      if (!queriedUser) return
      return (await SubscriptionsAPI.getSubscriptionByUserId(queriedUser.id)) as any
    },
    {
      cacheTime: 0,
      retry: false,
      refetchOnMount: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      enabled: false, //disable the query:
    },
  )

  // Query to get the user challenge info

  const {
    data: adminUserChallengeData,
    refetch: refetchUserChallenge,
    isError: userChallengeIsError,
    isRefetching: userChallengeIsRefetching,
  } = useQuery(
    ['adminUserQueryUserChallengeResult'],
    async () => {
      if (!queriedUser) return
      return (await ChallengeAPI.getActiveChallengeByUserId(queriedUser.id)) as any
    },
    {
      cacheTime: 0,
      retry: false,
      refetchOnMount: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
    },
  )

  const resetForm = () => {
    console.log('Resetting form')
    reset({ email: '' })
    setUserEmail('')
    setDifferedUserEmail('')
    setUserChallenge('')
    // invalidate the queries
    queryClient.setQueryData('adminUserQueryResult', null)
    queryClient.setQueryData('adminUserQueryUserSubscriptionResult', null)
    queryClient.setQueryData('adminUserQueryUserChallengeResult', null)
  }

  // When either the queriedUser or challenges change, refetch the user challenge and user subscription
  useEffect(() => {
    if (queriedUser && challenges) {
      refetchUserChallenge()
      if (queriedUser.id) {
        refetchUserSubscription()
      }
    }
    if (queriedUser) {
      reset({ email: queriedUser.email ?? '' })
    }
  }, [queriedUser, challenges])

  const fetchUserDetails = () => {
    refetch()
  }

  const handleEmailUpdate = async (data: any) => {
    const modifiedEmail = data.email
    const result = await AdminApi.updateEmail(queriedUser.id, modifiedEmail)
      .then((res: any) => {
        if (!res) {
          notyf.error('Response: Error updating user email: ')
          return
        } else {
          console.log('updated')
          notyf.success('User email updated!')
        }
      })
      .catch((error: any) => {
        notyf.error('Response: Error updating user email. ')
        console.error('Error updating user email: ', error, queriedUser.id, modifiedEmail)
      })
  }

  const handleStreakUpdate = async (data: any) => {
    const modifiedStreak = data.streak
    const modifiedHighestStreak = data.highestStreak
    const result = await AdminApi.updateUserStreak(queriedUser.id, modifiedStreak, modifiedHighestStreak)
      .then((res: any) => {
        if (!res) {
          notyf.error('Response: Error updating user streak: ')
          return
        } else {
          console.log('updated')
          notyf.success('User streak updated!')
        }
      })
      .catch((error: any) => {
        notyf.error('Response: Error updating user streak. ')
        console.error('Error updating user streak: ', error, queriedUser.id, modifiedStreak)
      })
  }

  const renderUser = () => {
    if (isError) {
      ;<div className="text-red-500 text-sm font-medium">Error fetching user</div>
    }

    const iosFieldsToRender = ['user_id', 'type', 'current_period_end', 'created_at']

    if (queriedUser) {
      console.log('158', { queriedUser }, { userChallengeIsRefetching })
      return (
        <div className="mt-4 ">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <img className="h-10 w-10 rounded-full" src={queriedUser.profilePicture} alt="" />
            </div>
            <div className="ml-4 flex">
              <div className="text-sm font-medium text-gray-900">
                {queriedUser.firstName} {queriedUser.lastName}
              </div>
              <form key={1} onSubmit={handleSubmit(handleEmailUpdate)} className="flex">
                <input
                  {...register('email')}
                  className="block w-full rounded-md border p-1  m-2 border-gray-700 shadow-md focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-gray-900"
                  defaultValue={queriedUser.email}
                  data-lpignore={'true'}
                />
                <button
                  type="submit"
                  className="inline-flex ml-12 items-center rounded-md border border-transparent bg-indigo-100 px-4 py-2 text-base font-small text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span className="text-xs ">Update User Email</span>
                </button>
              </form>
              <form key={1} onSubmit={handleSubmit(handleStreakUpdate)} className="flex ml-4">
                <label htmlFor="streak" className="block text-sm font-medium text-gray-700">
                  streak
                </label>
                <input
                  {...register('streak')}
                  className="block w-full rounded-md border p-1  m-2 border-gray-700 shadow-md focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-gray-900"
                  defaultValue={queriedUser.streak}
                  data-lpignore={'true'}
                />
                <label htmlFor="highestStreak" className="block text-sm font-medium text-gray-700">
                  Highest Streak
                </label>
                <input
                  {...register('highestStreak')}
                  className="block w-full rounded-md border p-1  m-2 border-gray-700 shadow-md focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-gray-900"
                  defaultValue={queriedUser.highestStreak}
                  data-lpignore={'true'}
                />
                <button
                  type="submit"
                  className="inline-flex ml-12 items-center rounded-md border border-transparent bg-indigo-100 px-4 py-2 text-base font-small text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span className="text-xs ">Update User Streak</span>
                </button>
              </form>
            </div>
          </div>
          <div className="mt-4 divide-y-2">
            <h1 className=" text-xl font-large"> Subscription</h1>
            <div className="flex flex-wrap space-y-8  items-center text-sm text-gray-500">
              <div
                className={`flex-shrink-0 inline-flex items-center justify-center h-2.5 w-2.5 rounded-full ${
                  queriedUser.subscription?.status === 'active' ? 'bg-green-400' : 'bg-red-400'
                }`}
              ></div>
              {adminUserSubscriptionData &&
                adminUserSubscriptionData.type === 'stripe' &&
                Object.keys(adminUserSubscriptionData).map((key, index) => {
                  if (key === 'id' || key === 'user_Id') return null
                  return (
                    <div key={key} className="">
                      <div className=" ml-8 text-sm text-gray-500  flex-col">
                        <span className="text-sm font-small ">{key} :</span> <div className="mt-1 text-xs text-gray-900">{adminUserSubscriptionData[key]}</div>
                      </div>
                    </div>
                  )
                })}

              {adminUserSubscriptionData &&
                adminUserSubscriptionData.type === 'ios' &&
                Object.keys(adminUserSubscriptionData).map((key, index) => {
                  if (iosFieldsToRender.includes(key)) {
                    return (
                      <div key={key} className="">
                        <div className=" ml-8 text-sm text-gray-500  flex-col">
                          <span className="text-sm font-small ">{key} :</span> <div className="mt-1 text-xs text-gray-900">{adminUserSubscriptionData[key]}</div>
                        </div>
                      </div>
                    )
                  }
                })}

              {adminUserSubscriptionData?.type === 'stripe' && (
                <button
                  className="  inline-flex  ml-12 items-center rounded-md border border-transparent bg-indigo-100 px-4 py-2 text-base font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 min-w-min"
                  onClick={() => {
                    // if we are in dev mode, open the stripe dashboard in a new tab with the test url
                    const baseUrl = process.env.NODE_ENV === 'production' ? 'https://dashboard.stripe.com' : 'https://dashboard.stripe.com/test'
                    const fullUrl = `${baseUrl}/customers/${adminUserSubscriptionData.stripe_customer_id}`
                    window.open(fullUrl)
                  }}
                >
                  <span>Open in Stripe</span>
                </button>
              )}
            </div>
          </div>
        </div>
      )
    }
  }

  const onSubmitUserChallenge = (data: any) => {
    console.log('data :>> ', data)
    const idToUpdate = adminUserChallengeData ? adminUserChallengeData.id : null
    AdminApi.updateUserChallenge(idToUpdate, data).then((res) => {
      if (!res) {
        notyf.error('Error updating user challenge: ' + res)
        return
      } else {
        resetForm()
        console.log('updated')
        notyf.success('User Challenge updated!')
      }
    })
  }

  const renderUserChallenge = (createNew = false) => {
    return (
      <ChallengeForm
        createNew={createNew}
        userChallengeData={
          createNew
            ? {
                id: null,
                user_id: queriedUser.id,
                challenge_id: null,
                start_date: dayjs().startOf('day').toISOString(),
                weekly_commitment: 3,
                with_music: false,
              }
            : adminUserChallengeData
        }
        challenges={challenges ?? []}
        onSubmitUserChallenge={onSubmitUserChallenge}
        onSubmitUserChallengeError={(error) => {
          notyf.error('Error Updating user challenge: ' + error)
        }}
      />
    )
  }

  return (
    <div>
      <div>
        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
          Find a user:
        </label>
        <div className="flex">
          <div className="relative mt-1 rounded-md shadow-sm w-1/2 ">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <EnvelopeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              type="email"
              name="email"
              id="email"
              className="block w-full mt-2 rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              placeholder="you@example.com"
              onChange={(e) => setUserEmail(e.target.value)}
              value={userEmail}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  fetchUserDetails()
                }
              }}
            />
          </div>
          <button
            type="button"
            className="inline-flex ml-12 items-center rounded-md border border-transparent bg-indigo-100 px-4 py-2 text-base font-small text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={(e) => fetchUserDetails()}
          >
            <span className="">Search</span>
          </button>
          <button
            type="button"
            className="inline-flex ml-12 items-center rounded-md border border-transparent bg-indigo-100 px-4 py-2 text-base font-small text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={(e) => resetForm()}
          >
            <span className="">Clear</span>
          </button>
        </div>
      </div>

      <div>
        {isRefetching && <div>loading...</div>}
        {isError && <div className="text-red-500 font-semibold">User not found</div>}
        {queriedUser && renderUser()}
        {/* if adminUserChallengeData is not present render a create challenge button */}
        {queriedUser && !adminUserChallengeData && !createUserChallenge && (
          <div className="mt-4   divide-y-2">
            <h1 className=" text-xl font-large"> UserChallenge</h1>
            <div className="text-sm"> User has not started a Challenge </div>
            <button
              type="button"
              className="inline-flex ml-12 mt-2 items-center rounded-md border border-transparent bg-indigo-100 px-4 py-2 text-base font-small text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={() => setCreateUserChallenge(true)}
            >
              <span className="">Create UserChallenge</span>
            </button>
          </div>
        )}
        {queriedUser && !adminUserChallengeData && createUserChallenge && renderUserChallenge(true)}

        {queriedUser && adminUserChallengeData && renderUserChallenge()}
      </div>

      {queriedUser && (
        <div className="">
          <h2 className="text-xl font-large"> User Achievements </h2>
          <AdminUserAchievements userId={queriedUser?.id} />
        </div>
      )}
    </div>
  )
}

export default AdminUser
