import React from 'react'
import styles from './VideoPlayer.module.scss'
import './VideoPlayer.module.scss'

interface VideoPlayerViewProps {
  id: string
  videoRef: React.RefObject<HTMLVideoElement>
}

export const VideoPlayerView: React.FC<VideoPlayerViewProps> = ({ id, videoRef }) => {
  return <video id={id} className={`video-js vjs-default-skin ${styles.videoPlayer}`} preload="auto" controls ref={videoRef} />
}
