import React from 'react'
import { VideoPlayerView } from './VideoPlayerView'

import { useVideoPlayerContext } from './VideoPlayerContext'

export const VideoPlayer: React.FC = () => {
  const { activeWorkout, videoRef } = useVideoPlayerContext()
  console.log('VideoPlayer', activeWorkout)
  return <VideoPlayerView id={activeWorkout?.id} videoRef={videoRef} />
}
