import { useContext, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useHistory, useLocation } from 'react-router'
import queryString from 'query-string'
import { getRecipeCategories, getRecipeMealTypes, getRecipes } from 'services/api/recipes'
import { MealType, Recipe, RecipeCategory } from 'types'
import { AuthStateContext } from 'context'

type RecipeData = {
  recipeCategories?: RecipeCategory[]
  mealTypes?: MealType[]
  recipes?: Recipe[]
  recipesLoading: boolean
  filterTypeId?: string
}

export const useRecipeData = (): RecipeData => {
  const location = useLocation()
  const history = useHistory()
  const { state: authState } = useContext(AuthStateContext)
  let userMealPreference: string | null = null
  if (authState.user?.preferences) {
    const mealTypePref = authState.user.preferences.find((i) => i.preference_type === 'meal-type')
    if (mealTypePref) {
      userMealPreference = mealTypePref.record.answer
    }
  }
  const { type, date } = queryString.parse(location.search) || {}
  let filterTypeId = ''

  const { data: recipeCategories } = useQuery('recipeCategories', getRecipeCategories, {
    staleTime: Infinity,
  })

  if (recipeCategories && type) {
    const foundCategory = recipeCategories.find((item) => item.title === type)
    if (foundCategory) {
      filterTypeId = foundCategory.id
    }
  }

  const { data: mealTypes } = useQuery('mealTypes', getRecipeMealTypes, {
    staleTime: Infinity,
  })

  const recipesQuery = useQuery(['recipes', { type: filterTypeId }], () => getRecipes({ type: filterTypeId, ...(userMealPreference && { tags: userMealPreference }) }), {
    enabled: !!filterTypeId && !!filterTypeId,
    staleTime: 60 * 60 * 1000, // 60 minutes
  })

  const { data: recipes, isLoading: recipesLoading } = recipesQuery

  useEffect(() => {
    console.log('type :>> ', type)
    if (!type) {
      console.log('no type found :>> ', recipeCategories)
      if (recipeCategories) {
        if (date) {
          console.log('date found :>> ')
          history.replace(`${location.pathname}?date=${date}&type=${recipeCategories[0].title}`)
        } else {
          history.replace(`${location.pathname}?type=${recipeCategories[0].title}`)
        }
      }
    }
  }, [type, recipeCategories, history, date, location])

  return {
    recipeCategories,
    mealTypes,
    recipes,
    recipesLoading,
    filterTypeId,
  }
}
