import { ConsoleSqlOutlined } from '@ant-design/icons'
import { get, patch, post } from './helpers'
import { AdminUpdateUserChallengeOptions } from './types'
import { AdminChallengeData } from './types'
import { updateUser } from './user'

// export const updateUserEmail = async (data: UpdateUserOptions): Promise<boolean> => {
//     const response = await post<>(`/admin/updateUserEmail`, data);
//     return response;
//   };

type updateUserChallenge = {
  challengeId: string
  startDate: string
  endDate: string
  userId: string
  completed: boolean
  cancelled: boolean
}

export const updateUserChallenge = async (userChallengeId: string, data: AdminUpdateUserChallengeOptions): Promise<boolean> => {
  console.log('userChallengeId :>> ', userChallengeId)
  console.log('data :>> ', data)

  const response = await post<any>(`/admin/updateUserChallenge/${userChallengeId}`, data)

  return !!response
}

export const fetchUserAchievements = async (userId: string): Promise<any> => {
  const response = await get<any>(`/admin/${userId}/userAchievements/`)
  console.log(response)

  return response
}

export const updateUserAchievement = async (uaId: string, data: any): Promise<any> => {
  const response = await patch<any>(`/admin/userAchievements/${uaId}/`, data)
  console.log(response)

  return response
}

export const fetchChallengeData = async (challengeId: string): Promise<any> => {
  const response = await get<AdminChallengeData>(`/admin/challenge/${challengeId}`)
  console.log(response)

  return response
}
export const downloadActiveSubEmails = async () => {
  try {
    const response = await get<string>(`/admin/subscriptions/active`)
    console.log(response)
    // Create a Blob from the CSV string
    const blob = new Blob([response], { type: 'text/csv' })
    // Create a link element
    const link = document.createElement('a')
    // Set the download attribute with a filename
    link.download = 'active_subscribers.csv'
    // Create a URL for the Blob and set it as the link's href
    link.href = window.URL.createObjectURL(blob)
    // Append the link to the body
    document.body.appendChild(link)
    // Programmatically click the link to trigger the download
    link.click()
    // Remove the link from the body
    document.body.removeChild(link)
  } catch (error) {
    console.error('Error downloading file:', error)
  }
}

export const updateEmail = async (userId: string, email: string): Promise<any> => {
  return await patch(`/admin/updateUserEmail`, { userId, email })
}

export const updateUserStreak = async (userId: string, streak: number, highestStreak: number): Promise<any> => {
  return await patch(`/admin/updateUserStreak`, { userId, streak, highestStreak })
}
