import React, { useContext, useEffect, useState } from 'react'
import { ChannelSort, StreamChat } from 'stream-chat'
import { AuthStateContext } from '../../context/AuthStateContext'
import { Chat } from 'stream-chat-react'
import SquadieChat from './components/SquadieChat'

import '@stream-io/stream-chat-css/dist/v2/css/index.css'
import './squadsOverides.css'

const stagingChannels = ['FC90FF62-82EB-4F3E-8C41-34C159BCE4D9', 'FoodInspo']
const productionChannels = [
  '746622B8-7CF0-46B4-BDD8-F51D10EE2744',
  '147A2CC5-4F39-46A8-A5F3-5E0E9AA59C21',
  '7633566E-2686-4953-96F4-59957670C3AB',
  '9157DD7A-0D46-424F-8331-6E3B0E5ED6DC',
  'BB4BBBE8-5596-4F5B-A1DB-1BFA2A44CFF4',
  'D0BB9C84-CE68-4EF6-A8FD-51CC58BFBB8F',
  'CE013D11-84AB-4B72-84CD-4C6959BB8BCE',
  '6668A7D8-4084-41D5-A819-87B4548233BC',
  'BC522AD4-9741-4198-AFA3-13C16F85765C',
  '295CE4A3-3A4A-485C-B4BF-D62D4E130E2B',
  '7236CE01-4EC5-4079-9843-D1B32AF4700D',
  'A1F75CCF-A64F-4AC7-BAAD-3792C515AF3D',
  '034BD231-0E9A-4D2C-9D1B-DBF62907060F',
  'leanliftchallenges',
]
let channels

//set channels based off of environment
if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  channels = productionChannels
} else {
  channels = stagingChannels
}

const filters = { id: { $in: channels } }
const options = { state: true, presence: true, limit: 20 }
const sort = { last_message_at: -1 } as ChannelSort

export const Squads: React.FC = () => {
  const { state: authState } = useContext(AuthStateContext)
  const { user } = authState

  const [client, setClient] = useState<any>(null)

  useEffect(() => {
    const CHAT_API = process.env.REACT_APP_STREAM_CHAT_API || ''
    if (CHAT_API === '') return
    const newClient = new StreamChat(CHAT_API)
    const handleConnectionChange = ({ online = false }) => {
      if (!online) return console.log('connection lost')
      setClient(newClient)
    }
    newClient.on('connection.changed', handleConnectionChange)

    if (user) {
      newClient.connectUser({ id: user.id, name: user.firstName }, user.chatToken)
    }

    return () => {
      newClient.off('connection.changed', handleConnectionChange)
      newClient.disconnectUser().then(() => console.log('connection closed'))
    }
  }, [])

  if (!client) return null

  return (
    <div className="transition-all relative">
      <Chat client={client} theme="">
        <SquadieChat filters={filters} options={options} sort={sort} />
      </Chat>
    </div>
  )
}
