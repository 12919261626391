import React, { useContext, useEffect, useMemo, useState } from 'react'
import styles from './ChallengeOverview.module.scss'
import { Box, Button, LoadingIndicator } from 'shared/components'
import { Challenge } from 'types'
import { useHistory, useLocation, useParams } from 'react-router'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { getChallengeById } from 'services/api/challenges'
import { ReactComponent as Squads } from 'assets/images/squads-header.svg'
import { ReactComponent as Check } from 'assets/icons/check.svg'
import { UserChallengeContext } from 'context/UserChallengeContext'
import { CreateUserChallengeOptions, UpdateChallengeVariables } from 'services/api/types'
import { dayjs } from 'shared/functions'
import { ChallengeAPI } from 'services/api'
import { NotyfContext } from 'context/NotyfContext'
import { ActiveChallenge } from '../../../types/index'
import { ChallengeType } from '../../../constants'
import FlexChallengeModal from './FlexChallengeModal'
import { isFlexChallenge } from 'shared/functions/utils'
import { ChallengeUtils } from '../../../shared/functions'
import RedPill from '../../SignUp/components/RedPill'
import { Switch } from '@headlessui/react'
import { set } from 'react-hook-form'

type ChallengeOverviewParams = {
  challengeId: string
}

type ChallengeOverviewLocationState = {
  challenge?: Challenge
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export const ChallengeOverview: React.FC = () => {
  const history = useHistory()
  const queryClient = useQueryClient()
  // const achievements = useAchievements()
  const { state: locationState = {} } = useLocation<ChallengeOverviewLocationState>()
  const notyf = useContext(NotyfContext)
  const startUserChallengeMutation = useMutation((createOptions: CreateUserChallengeOptions) => ChallengeAPI.startUserChallenge(createOptions))
  const startFlexUserChallengeMutation = useMutation((createOptions: CreateUserChallengeOptions) => ChallengeAPI.startFlexUserChallenge(createOptions))
  const updatePlanMutation = useMutation(({ userChallengeId, options }: UpdateChallengeVariables) => ChallengeAPI.updateUserChallenge(userChallengeId, options))
  const { challengeId = '' } = useParams<ChallengeOverviewParams>()
  const [challenge, setChallenge] = useState<Challenge | undefined>(locationState.challenge)
  const challengeQuery = useQuery(['challengeById', challengeId], () => getChallengeById(challengeId), {
    enabled: challenge === undefined,
  })
  const { state: challengeState } = useContext(UserChallengeContext)
  const { activeChallenge } = challengeState
  const { data: challengeData, isLoading: challengeLoading } = challengeQuery

  useEffect(() => {
    if (!challenge && challengeData) {
      console.log('Setting Challenge from Query :>> ', challengeData)
      setChallenge(challengeData)
    }
  }, [challenge, challengeData])

  const [showFlexModal, setShowFlexModal] = useState(false)
  const [withMusic, setWithMusic] = useState<boolean | undefined>(undefined)
  const [commitment, setCommitment] = React.useState(challenge?.defaultCommitment || 3)

  const isQueueable = useMemo(() => {
    if (!challenge) return false
    return ChallengeUtils.isQueueable(challenge.releaseDate)
  }, [challenge])

  const isReleased = useMemo(() => {
    if (!challenge) return false
    return ChallengeUtils.isReleased(challenge.releaseDate)
  }, [challenge])

  const planChallenge = async (startNow = false) => {
    if (!challenge) return

    try {
      // Cancel Previous Challenge
      if (activeChallenge) {
        const updateVariables: UpdateChallengeVariables = {
          userChallengeId: activeChallenge.id,
          options: {
            cancelled: true,
          },
        }
        await updatePlanMutation.mutateAsync(updateVariables)
      }
      let start_date
      if (startNow) {
        start_date = dayjs().startOf('day').utc().toISOString()
      } else if (dayjs().get('day') === 1) {
        // If today is Monday, start challenge next Monday
        start_date = dayjs().weekday(8).startOf('day').utc().toISOString()
      } else if (dayjs().get('day') > 1) {
        // Already after Monday, start challenge next Monday
        start_date = dayjs().weekday(8).startOf('day').utc().toISOString()
      } else if (dayjs().get('day') < 1) {
        // Before Monday, start challenge this Monday
        start_date = dayjs().weekday(1).startOf('day').utc().toISOString()
      } else {
        // if something mathematically impossible happens, start challenge today
        start_date = dayjs().startOf('day').utc().toISOString()
      }

      // Plan Regular Challenge

      const createOptions: CreateUserChallengeOptions = {
        challenge_id: challenge.id,
        start_date,
        is_active: true,
        with_music: withMusic,
      }
      // Plan Flex Challenge

      if (isFlexChallenge(challenge)) {
        createOptions.workout_commitment = commitment
        await startFlexUserChallengeMutation.mutateAsync(createOptions)
      } else {
        await startUserChallengeMutation.mutateAsync(createOptions)
      }

      queryClient.invalidateQueries('activeChallenge')
      queryClient.invalidateQueries('todayContent')
      queryClient.invalidateQueries(`plannerWorkouts`)
      notyf.success('Challenge updated!')

      if (!(challenge.type?.title === ChallengeType.Flex)) {
        history.push('/planner')
      } else {
        history.push('/today')
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log('error :>> ', error)
      notyf.error(error.message)
    }
  }

  const onClickPlanAction = async () => {
    if (!challenge) return
    console.log(`onClickPlanAction`, challenge.title, 'active Challenge:', activeChallenge)

    if (challenge.type && challenge.type.title === ChallengeType.Flex) {
      setShowFlexModal(true)
    } else {
      planChallenge()
    }
  }

  const onClickCancelPlan = async () => {
    if (!activeChallenge) {
      return
    }
    try {
      const updateVariables: UpdateChallengeVariables = {
        userChallengeId: activeChallenge.id,
        options: {
          cancelled: true,
        },
      }
      await updatePlanMutation.mutateAsync(updateVariables)
      notyf.success('Challenge cancelled!')
      queryClient.invalidateQueries('activeChallenge')
      queryClient.invalidateQueries('todayContent')
      queryClient.invalidateQueries(`plannerWorkouts`)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log('error :>> ', error)
      notyf.error(error.message)
    }
  }

  const renderChallengeActionButton = (challenge: Challenge, activeChallenge: ActiveChallenge | null | undefined, onClickPlanAction: () => void, onClickCancelPlan: () => void) => {
    if (!isReleased && !isQueueable) {
      const text = `Coming Soon!`
      return (
        <Button
          containerStyle={`${styles.selectPlanBtn} bg-coral mb-4 lg:max-w-lg w-full md:w-[70%] mx-auto disabled:bg-gray-600`}
          onClick={() => {
            return null
          }}
          label={text}
          loading={startUserChallengeMutation.isLoading || updatePlanMutation.isLoading || startFlexUserChallengeMutation.isLoading}
          disabled
        />
      )
    }

    if (activeChallenge && activeChallenge.challenge_id === challenge.id) {
      return (
        <Button
          containerStyle={`${styles.selectPlanBtn} bg-coral mb-4 lg:max-w-lg  w-full  md:w-[70%] mx-auto`}
          onClick={onClickCancelPlan}
          label="Cancel Challenge"
          loading={updatePlanMutation.isLoading}
        />
      )
    }

    const startNowAdditionalText = dayjs().get('day') === 1 ? 'Next Monday' : ''
    return (
      <Button
        containerStyle={`${styles.selectPlanBtn} bg-coral mb-4 lg:max-w-lg w-full md:w-[70%] mx-auto `}
        onClick={onClickPlanAction}
        label={activeChallenge ? 'Change to Challenge ' : `Start Challenge ${startNowAdditionalText}`}
        loading={startUserChallengeMutation.isLoading || updatePlanMutation.isLoading || startFlexUserChallengeMutation.isLoading}
      />
    )
  }

  if (!challenge || challengeLoading) {
    return <LoadingIndicator />
  }

  const numberOfDays = challenge.numberOfWeeks ? challenge.numberOfWeeks * 7 : challenge.totalWorkouts
  return (
    <>
      {showFlexModal && (
        <FlexChallengeModal
          challenge={challenge}
          isOpen={showFlexModal}
          setIsOpen={setShowFlexModal}
          handleStartChallenge={planChallenge}
          commitment={commitment}
          setCommitment={setCommitment}
          withMusic={withMusic ?? null}
          setWithMusic={setWithMusic}
          isQueueable={isQueueable}
          isReleased={isReleased}
        />
      )}
      <Box className={`${styles.container} mx-10`}>
        <Box className={styles.imageContainer}>
          <img src={challenge.image} />
        </Box>
        <Box className={styles.header}>
          <p className={styles.title}>{challenge.title}</p>
          <p className={styles.metadata}>{`${numberOfDays} Days${challenge.equipment ? ` • With Weights` : ' • Bodyweight Only'}`}</p>
        </Box>
        {/* <div className="">
          <p className={styles.contentTitle}>ASSOCIATED ACHIEVEMENTS</p>
          <div className={`grid grid-cols-1 md:grid-cols-2`}>
            {console.log({ associatedAchievementIds })}
            {associatedAchievementIds.map((item) => {
              let achievement = achievements.get(item)
              if (!achievement) {
                return null
              }

              return (
                <div key={item} className={`${styles.includesItem} flex `}>
                  <div className="item"></div>
                  <div className={`${styles.checkContainer} justify-center flex`}>
                    <Check />
                  </div>
                  <p>{achievement.name}</p>
                  <p></p>
                </div>
              )
            })}
          </div>
        </div> */}
        <Box className={styles.content}>
          {!isReleased && (
            <RedPill active={false} className="border border-black border-2  px-8 mx-auto  border-solid">
              Challenge Launches {new Date(challenge.releaseDate).toLocaleString('en-US', { month: 'short', day: 'numeric' })}
            </RedPill>
          )}
          {!isFlexChallenge(challenge) && challenge.hasMusic && (
            <section id="withMusic">
              <Switch.Group as="div" className="flex items-center justify-end ">
                <span className="flex p-4 flex-col">
                  <Switch.Label as="span" className="text-sm font-medium leading-6 text-gray-900   uppercase" passive>
                    Music?
                  </Switch.Label>
                  {/* <Switch.Description as="span" className="text-sm text-gray-500">
                    Show the text blurb that informs Squadies to Contact us if they saw a Promo on Social Media.
                  </Switch.Description> */}
                </span>
                <Switch
                  checked={withMusic}
                  onChange={setWithMusic}
                  className={classNames(
                    withMusic ? 'bg-coral' : 'bg-gray-200',
                    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-teal focus:ring-offset-2',
                  )}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      withMusic ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                    )}
                  />
                </Switch>
              </Switch.Group>
            </section>
          )}

          <div className="">{renderChallengeActionButton(challenge, activeChallenge, onClickPlanAction, onClickCancelPlan)}</div>
          <p className={styles.contentTitle}>CHALLENGE SUMMARY</p>
          <p className={styles.challengeSummary}>{challenge.summary}</p>
          <p className={styles.contentTitle}>CHALLENGE INCLUDES:</p>
          <div className={`grid grid-cols-1 md:grid-cols-2`}>
            {challenge.includes.map((item) => {
              return (
                <div key={item} className={`${styles.includesItem} flex `}>
                  <div className="item"></div>
                  <div className={`${styles.checkContainer} justify-center flex`}>
                    <Check />
                  </div>
                  <p>{item}</p>
                </div>
              )
            })}
          </div>
          <Box className={styles.banner}>
            <Squads />
            <p>Join the Squad and become better than ever!</p>
          </Box>
        </Box>
      </Box>
    </>
  )
}
