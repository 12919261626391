import firebase from './config';
import { resetUserStateAction } from 'context/actions/authStateActions';
import { AuthActionTypes } from 'types';
const { auth } = firebase;

interface UserAuthData {
  email: string;
  password: string;
}

interface CreateUserAuthData extends UserAuthData {
  firstName?: string;
}

export const loginFirebaseUserWithEmail = async (data: UserAuthData): Promise<firebase.auth.UserCredential> => {
  try {
    const res = await auth().signInWithEmailAndPassword(data.email, data.password);
    return res;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.log('error :>> ', error);
    throw new Error(`Wrong password or invalid email`);
  }
};

export const createFirebaseUserWithEmail = async (data: CreateUserAuthData): Promise<firebase.auth.UserCredential> => {
  try {
    const res = await auth().createUserWithEmailAndPassword(data.email, data.password);
    return res;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    let errorMessage = 'Something went wrong';

    if (error.message.includes('The email address is already in use')) {
      errorMessage = 'The email address is already in use by another account';
    } else if (error.message.includes('The given password is invalid.')) {
      errorMessage = 'The password requires at least 6 characters';
    }
    throw new Error(errorMessage);
  }
};

export const updateEmail = async (newEmail: string): Promise<void> => {
  const currentUser = auth().currentUser;
  if (currentUser !== null) {
    return currentUser.updateEmail(newEmail);
  }
  return;
};

type FirebaseUpdateProfileOptions = { displayName?: string; photoUrl?: string };

export const updateProfile = async (options: FirebaseUpdateProfileOptions): Promise<void> => {
  const currentUser = auth().currentUser;
  if (currentUser !== null) {
    return currentUser.updateProfile(options);
  }
  return;
};

export const updatePassword = async (newPassword: string): Promise<void> => {
  const currentUser = auth().currentUser;
  if (currentUser !== null) {
    return currentUser.updatePassword(newPassword);
  }
};

export const sendPasswordResetEmail = async (email: string): Promise<void> => {
  return auth().sendPasswordResetEmail(email, {
    url: `${process.env.REACT_APP_APP_URL}/login`,
  });
};

/**
 * Completes the password reset process, given a confirmation code and new password.
 * @param {string} code
 * @param {string} password New Password entered
 */
export const confirmPasswordReset = (code: string, password: string): Promise<void> => {
  return auth().confirmPasswordReset(code, password);
};

/**
 * Checks a password reset code sent to the user by email or other out-of-band mechanism.
 * @param {string} code
 */
export const verifyPasswordResetCode = (code: string): Promise<string> => {
  return auth().verifyPasswordResetCode(code);
};

export const signOut = async (dispatch: React.Dispatch<AuthActionTypes>): Promise<void> => {
  try {
    await auth().signOut();
    dispatch(resetUserStateAction());
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.log(error);
  }
};
