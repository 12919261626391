import React, { useEffect, useRef, useContext, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory, useLocation, useParams } from 'react-router'
import { Workout } from 'types'
import queryString from 'query-string'
import { ChallengeAPI, CompletedWorkoutsAPI, WorkoutsAPI } from 'services/api'
import { CreateCompletedWorkoutOptions } from 'services/api/types'
import { dayjs } from 'shared/functions'
import { useVideo } from 'hooks/useVideo'
import videojs from 'video.js'
import { UserChallengeContext } from 'context/UserChallengeContext'
import { useUserAchievementCriteriaActions } from 'hooks/useUserAchievementCriteria'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import airplay from '@silvermine/videojs-airplay'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import chromecast from '@silvermine/videojs-chromecast'
import { useUserAchievements } from 'hooks/useUserAchievements'

airplay(videojs)
chromecast(videojs, { preloadWebComponents: true })

const VideoPlayerContext = React.createContext<any>(null)
VideoPlayerContext.displayName = 'VideoPlayerContext'

type WorkoutOverviewParams = {
  workoutId: string
}

type WorkoutOverviewLocationState = {
  workout?: Workout
}

export const VideoPlayerContextProvider: React.FC<{ children: React.ReactNode }> = ({ children, ...props }: any) => {
  const queryClient = useQueryClient()
  const history = useHistory()
  const { state: locationState = {}, search } = useLocation<WorkoutOverviewLocationState>()
  const { challengeWorkout } = queryString.parse(search) || {}
  const { workoutId = '' } = useParams<WorkoutOverviewParams>()
  const [workout, setWorkout] = useState<Workout | undefined>(locationState.workout)
  const [activeWorkout, setActiveWorkout] = useState<Workout | undefined>(workout)
  const [isFinisher, setIsFinisher] = useState(false)

  const { state: userChallengeContextState } = useContext(UserChallengeContext)
  const { activeChallenge } = userChallengeContextState
  console.log({ activeChallenge })
  const playWithMusic = activeChallenge?.with_music ?? false
  const { playerRef, videoRef, showWorkoutComplete } = useVideo(workout, playWithMusic)
  const { refresh: refreshUac } = useUserAchievementCriteriaActions()
  const { refresh: refreshUa } = useUserAchievements()

  const workoutsQuery = useQuery(
    ['workoutById', workoutId, challengeWorkout],
    () => (challengeWorkout ? ChallengeAPI.getChallengeWorkoutByChallengeWorkoutId(challengeWorkout as string) : WorkoutsAPI.getWorkoutById(workoutId)),
    {
      enabled: workout === undefined,
    },
  )

  const { mutate: createCompletedWorkout, isLoading: createCompletedLoading } = useMutation(
    (options: CreateCompletedWorkoutOptions) => CompletedWorkoutsAPI.createCompletedWorkout(options),
    {
      onSuccess: async (res) => {
        console.log('completedWorkout :>> ', res)

        console.log('%c ###- refreshing uac and ua', 'color: orange')
        console.log({ refreshUa, refreshUac })
        await refreshUac()
        await refreshUa()
        queryClient.invalidateQueries('leanStreak')
        queryClient.invalidateQueries('plannerWorkouts')
        queryClient.invalidateQueries('completedWorkouts')
        queryClient.invalidateQueries('activeChallenge')
        queryClient.invalidateQueries('todayContent')
        queryClient.invalidateQueries('achievements')
        history.push(`/workouts/${res.workout_id}/complete`, {
          workout: workout,
          score: res.score,
        })
      },
    },
  )

  const { data: workoutData, isLoading: workoutLoading } = workoutsQuery

  const handlePlayClick = (item: Workout) => {
    console.log('item :>> ', item)
    if (playerRef.current) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      playerRef.current?.playlist.next()
      setActiveWorkout(item)
    }
  }
  const onClickBackToWorkout = () => {
    if (playerRef.current) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      playerRef.current?.playlist.previous()
      setActiveWorkout(workout)
    }
  }

  const onClickWorkoutComplete = () => {
    console.log('onClickWorkoutComplete :>> ', workout)
    if (workout) {
      createCompletedWorkout({
        workout_id: workout.id,
        completed_date: dayjs().startOf('day').utc().toISOString(),
        ...(workout.challenge_workout
          ? {
              challenge_workout_id: workout.challenge_workout?.id as string,
              user_challenge_id: workout.challenge_workout?.user_challenge_id,
            }
          : {}),
        score: workout.score,
      })
    }
  }

  useEffect(() => {
    if (!workout && workoutData) {
      console.log('Setting Workout from Query :>> ', workoutData)
      setWorkout(workoutData)
      setActiveWorkout(workoutData)
    }
  }, [workout, workoutData])

  useEffect(() => {
    if (playerRef.current) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setIsFinisher(playerRef.current?.playlist.currentItem() === 1)
    }
  }, [])

  return (
    <VideoPlayerContext.Provider
      value={{
        workoutLoading,
        activeWorkout,
        handlePlayClick,
        isFinisher,
        onClickBackToWorkout,
        showWorkoutComplete,
        onClickWorkoutComplete,
        createCompletedLoading,
        videoRef,
        setActiveWorkout,
      }}
    >
      {children}
    </VideoPlayerContext.Provider>
  )
}

export const useVideoPlayerContext = () => {
  const context = React.useContext(VideoPlayerContext)
  if (context === undefined) throw new Error('useContext must be used within a Provider')

  return context
}
