import ChatArea from './ChatArea'
import React, { useState, useEffect } from 'react'
import { ChannelSort } from 'stream-chat'
import { Channel, ChannelList, useChatContext } from 'stream-chat-react'

const SquadieChatProps = {
  filters: {},
  options: { state: true, presence: true, limit: 20 },
  sort: { last_message_at: -1 } as ChannelSort,
}

const SquadieChat = ({ filters, sort, options } = SquadieChatProps) => {
  const [showChat, setShowChat] = useState(false)
  const [showChannel, setShowChannel] = useState(true)
  const [showChannelList, setShowChannelList] = useState(true)
  const chatContext = useChatContext()
  useEffect(() => {
    if (chatContext.channel !== undefined) return setShowChat(true)
  }, [chatContext.channel])

  useEffect(() => {
    if (!chatContext.channel) return
    if (!chatContext.channel.state) return
    const threads = chatContext.channel && chatContext.channel.state.threads
    const activeThread = Object.keys(threads).length > 0 && threads[0]
    if (activeThread) return setShowChannel(false)
  }, [chatContext.channel?.state.threads])

  return (
    <>
      <div className="max-w-full h-[calc(100vh-4rem)]   flex overflow-hidden">
        <div
          className={`w-full md:w-1/2    transition-[width] ${showChannelList ? '' : 'w-0 md:w-0'} duration-500 `}
          onClick={() => setShowChannelList(!showChannelList)}
        >
          <ChannelList filters={filters} sort={sort} options={options} setActiveChannelOnMount={false} />
        </div>
        {/* clickable vertical divider */}
        <div
          className={`cursor-pointer   h-full flex bg-[#dbdde1] items-centers   border-l-2 border-r-2 border-slate-300 bg-gradient-to-r from-gray-300 via-[#dbdde1] to-gray-300`}
          onClick={() => setShowChannelList(!showChannelList)}
        >
          <div className="my-auto">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M7.5 21L3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5"
              />
            </svg>
          </div>
        </div>

        <div className={`w-full`}>
          <Channel>
            <ChatArea />
          </Channel>
        </div>
      </div>
    </>
  )
}
export default SquadieChat
