import React from 'react'
import AdminInputField from './AdminInputField'
import { AchievementType } from 'types'
import AdminButton from './AdminButton'
import type { AchievementFormState } from 'types'

import { useAchievementForm } from '../../../hooks/useAchievementForm'
type Props = {
  submitCallback: (formState: AchievementFormState) => void
  initialState?: AchievementFormState
}
const AchievementForm = ({ submitCallback, initialState }: Props) => {
  const { formState, setFieldValue, handleSubmit } = useAchievementForm(initialState)

  const renderErrors = () => {
    const errors = Object.values(formState?.validationErrors)
    console.log('errors', { errors })
    return errors.map((error, index) => {
      return (
        <li key={index} className="mx-2">
          {error}
        </li>
      )
    })
  }

  return (
    <form onSubmit={(e) => handleSubmit(e, submitCallback)} className="w-full max-w-lg mt-3 mx-auto">
      <AdminInputField label="Name" key={'name'} fieldKey={'name'} value={formState.name} setFieldValue={setFieldValue} type="text" errorText={formState?.validationErrors?.name} />

      <div className="w-full px-3 mt-6  md:mb-0">
        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-description-md">
          Description
        </label>
        <textarea
          className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
          id="grid-description-md"
          value={formState.descriptionMd}
          onChange={(e) => setFieldValue('descriptionMd', e.target.value)}
        />
        <div className="text-xs pl-3 mt-0 font-thin text-red-600"> {formState?.validationErrors?.descriptionMd}</div>
      </div>

      <div className="w-full px-3 mt-6 mb-6 md:mb-0">
        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-achievement-type">
          Achievement Type
        </label>
        <select
          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          id="grid-achievement-type"
          value={formState.type}
          onChange={(e) => setFieldValue('type', e.target.value)}
        >
          <option value={AchievementType.DYNAMIC}>DYNAMIC</option>
          <option value={AchievementType.EVENT}>EVENT</option>
          <option value={AchievementType.REGULAR}>REGULAR</option>
        </select>
        <div className="text-xs pl-3 mt-0 font-thin text-red-600"> {formState?.validationErrors?.type}</div>
      </div>
      <AdminInputField
        label="Start DateTime"
        key={'startDate'}
        fieldKey={'startDate'}
        value={formState.startDate}
        setFieldValue={setFieldValue}
        type="datetime-local"
        errorText={formState?.validationErrors?.startDate}
      />
      <AdminInputField
        label="End Date"
        key={'endDate'}
        fieldKey={'endDate'}
        value={formState.endDate}
        setFieldValue={setFieldValue}
        type="datetime-local"
        errorText={formState?.validationErrors?.endDate}
      />
      <AdminInputField
        label="Duration (Days)"
        key={'durationDays'}
        fieldKey={'durationDays'}
        value={formState.durationDays}
        setFieldValue={setFieldValue}
        type="number"
        errorText={formState?.validationErrors?.durationDays}
      />

      <AdminInputField
        label="Badge Image URL"
        key={'badgeImageUrl'}
        fieldKey={'badgeImageUrl'}
        value={formState.badgeImageUrl}
        setFieldValue={setFieldValue}
        type="text"
        errorText={formState?.validationErrors?.badgeImageUrl}
      />
      <div className="w-full px-3 mt-6 mb-6 md:mb-0">
        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-active">
          Active
        </label>
        <input className="mr-2 leading-tight" type="checkbox" id="grid-active" checked={formState.active} onChange={(e) => setFieldValue('active', !formState.active)} />
        <div className="text-xs pl-3 mt-0 font-thin text-red-600"> {formState?.validationErrors?.active}</div>
      </div>
      <div className="w-full px-3 mt-6 mb-6 md:mb-0">
        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-active">
          Appears in the &apos;Available Achievements&apos; list
        </label>
        <input
          className="mr-2 leading-tight"
          type="checkbox"
          id="grid-available"
          checked={formState.available}
          onChange={(e) => setFieldValue('available', !formState.available)}
        />
        <div className="text-xs pl-3 mt-0 font-thin text-red-600"> {formState?.validationErrors?.active}</div>
      </div>
      <div className="flex mb-6 mt-6">
        <AdminInputField
          label="1 Star Min %"
          key={'oneStarMinPercent'}
          fieldKey={'oneStarMinPercent'}
          value={formState.oneStarMinPercent}
          setFieldValue={setFieldValue}
          type="number"
          className="w-1/3 px-3 mb-6 md:mb-0"
          errorText={formState?.validationErrors?.oneStarMinPercent}
        />
        <AdminInputField
          label="2 Star Min %"
          key={'twoStarMinPercent'}
          fieldKey={'twoStarMinPercent'}
          value={formState.twoStarMinPercent}
          setFieldValue={setFieldValue}
          type="number"
          className="w-1/3 px-3 mb-6 md:mb-0"
          errorText={formState?.validationErrors?.twoStarMinPercent}
        />
        <AdminInputField
          label="3 Star Min %"
          key={'threeStarMinPercent'}
          fieldKey={'threeStarMinPercent'}
          value={formState.threeStarMinPercent}
          setFieldValue={setFieldValue}
          type="number"
          className="w-1/3 px-3 mb-6 md:mb-0"
          errorText={formState?.validationErrors?.threeStarMinPercent}
        />
      </div>
      <AdminInputField
        label="Publish Date"
        key={'publishDate'}
        fieldKey={'publishDate'}
        value={formState.publishDate}
        setFieldValue={setFieldValue}
        type="datetime-local"
        errorText={formState?.validationErrors?.publishDate}
      />
      <AdminInputField
        label="Star Congratulations Text"
        key={'starCongratsText'}
        fieldKey={'starCongratsText'}
        value={formState.starCongratsText}
        setFieldValue={setFieldValue}
        type="text"
        errorText={formState?.validationErrors?.starCongratsText}
      />
      <div className="flex justify-end mt-3">
        <AdminButton type="submit" label="Submit" />
      </div>
      {formState.errorMsg && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 mt-2  text-xs rounded relative" role="alert">
          <strong className="font-bold mx-2">Oh snap!</strong> <br></br>
          <span className="block sm:inline whitespace-pre-line">{formState?.validationErrors}</span>
          <div className="mx-2">
            <ul className="list-disc list-inside">
              {
                // aggregate the errors and set them as bullets to the bottom of the form
                renderErrors()
              }
            </ul>
          </div>
        </div>
      )}
    </form>
  )
}

export default AchievementForm
